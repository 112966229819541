import React, { useContext, createContext } from 'react';

export interface BrandImagesContextProps {
    imgBalanceBG: string;
    imgLogoPoweredByT2TKN: string;
    imgT2LogoCoin: string;
    imgT2LogoFooter: string;
    imgT2LogoLong: string;
}
const BrandImagesContext = createContext<BrandImagesContextProps>({
    imgBalanceBG: '',
    imgLogoPoweredByT2TKN: '',
    imgT2LogoCoin: '',
    imgT2LogoFooter: '',
    imgT2LogoLong: '',
});

export const useBrandImagesContext = () =>
    useContext<BrandImagesContextProps>(BrandImagesContext);

interface Props {
    children: React.ReactNode;
    brandImages: BrandImagesContextProps;
}
export const BrandImagesContextProvider: React.FC<Props> = ({
    children,
    brandImages,
}) => {
    return (
        <BrandImagesContext.Provider value={brandImages}>
            {children}
        </BrandImagesContext.Provider>
    );
};
