import { gql } from '@apollo/client';

export const CREATE_OFFER_COMMENT = gql`
    mutation CreateOfferComment($id: String!, $input: JSON!) {
        createOfferComment(id: $id, input: $input)
            @rest(
                type: "CreateOfferComment"
                path: "/offers/{args.id}/comments"
                method: "POST"
            ) {
            id
            text
            commenter
            commenterName
            created
        }
    }
`;
export const FETCH_COMMENTS_FOR_OFFER = gql`
    query CommentsForOffer($id: String!) {
        commentsForOffer(id: $id)
            @rest(
                type: "CommentsForOffer"
                method: "GET"
                path: "/offers/{args.id}/comments"
            ) {
            id
            text
            commenter
            commenterName
            created
        }
    }
`;
export const DELETE_COMMENT = gql`
    mutation DeleteComment($id: String!) {
        deleteComment(id: $id, input: {})
            @rest(
                type: "DeleteComment"
                path: "/comments/{args.id}"
                method: "DELETE"
            ) {
            NoResponse
        }
    }
`;
export const EDIT_COMMENT = gql`
    mutation EditComment($id: String!, $input: JSON!) {
        editComment(id: $id, input: $input)
            @rest(
                type: "EditComment"
                path: "/comments/{args.id}"
                method: "POST"
            ) {
            id
            text
            commenter
            commenterName
            created
        }
    }
`;

export const FETCH_COMMENTS_FOR_LISTING = gql`
    query CommentsForListing($id: String!) {
        commentsForListing(id: $id)
            @rest(
                type: "CommentsForListing"
                method: "GET"
                path: "/listings/{args.id}/comments"
            ) {
            id
            text
            commenter
            commenterName
            created
        }
    }
`;

export const CREATE_LISTING_COMMENT = gql`
    mutation CreateListingComment($id: String!, $input: JSON!) {
        createListingComment(id: $id, input: $input)
            @rest(
                type: "CreateListingComment"
                path: "/listings/{args.id}/comments"
                method: "POST"
            ) {
            id
            text
            commenter
            commenterName
            created
        }
    }
`;
