import {
    useMutation,
    MutationHookOptions,
    MutationTuple,
} from '@apollo/client';

import {
    SendResetPasswordNotificationData,
    SendResetPasswordNotificationParams,
} from './types';
import { SEND_RESET_PASSWORD_NOTIFICATION } from './gql';

type Options = MutationHookOptions<
    SendResetPasswordNotificationData,
    SendResetPasswordNotificationParams
>;

const useSendResetPasswordNotification = (
    options: Options,
): MutationTuple<
    SendResetPasswordNotificationData,
    SendResetPasswordNotificationParams
> => useMutation(SEND_RESET_PASSWORD_NOTIFICATION, options);

export default useSendResetPasswordNotification;
