import { Listing } from '../Listing';
import { CurrencyType } from '../Currency';
import { ShippingAddress } from '../Auth/shippingAddress/types';

export interface CreateOfferParams {
    id: string;
    input: {
        price: number;
        currency: string;
        description?: string;
        address?: ShippingAddress;
    };
}
export enum OfferStatus {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    EXPIRED = 'EXPIRED',
    NOT_ACCEPTED = 'NOT_ACCEPTED',
}

interface ShippingData {
    cost?: number;
    validation?: string;
}
export interface Offer {
    id: string;
    listing: Listing;
    price: number;
    currency: CurrencyType;
    description?: string;
    address?: ShippingAddress;
    expiration: number;
    status: OfferStatus;
    shippingData?: ShippingData;
}

export interface CreateOfferData {
    createOffer: {
        id: string;
        status: OfferStatus;
        listing: Listing;
        price: number;
        currency: string;
        description?: string;
        address?: ShippingAddress;
        expiration: number;
    };
}

export interface FetchOffersForListingParams {
    id: string;
}

export interface OfferForListing {
    id: string;
    price: number;
    status: OfferStatus;
    currency: CurrencyType;
    description?: string;
    address?: ShippingAddress;
    expiration: number;
    shippingData?: ShippingData;
}

export interface FetchOffersForListingData {
    offersForListing: OfferForListing[];
}

export interface FetchMyOffersData {
    fetchMyOffers: Offer[];
}
