import { useQuery, QueryHookOptions } from '@apollo/client';

import { MeDataType } from './types';
import { FETCH_ME } from './gql';

type Options = QueryHookOptions<MeDataType, {}>;

const useFetchMe = (options: Options) => useQuery(FETCH_ME, options);

export default useFetchMe;
