import {
    useMutation,
    MutationHookOptions,
    MutationTuple,
} from '@apollo/client';

import { LoginParams, LoginData } from './types';
import { LOGIN } from './gql';

type Options = MutationHookOptions<LoginData, LoginParams>;

const useLogin = (options: Options): MutationTuple<LoginData, LoginParams> =>
    useMutation(LOGIN, options);

export default useLogin;
