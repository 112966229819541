import { useQuery, QueryHookOptions, QueryResult } from '@apollo/client';

import { FETCH_MY_OFFERS } from './gql';
import { FetchMyOffersData } from './types';

type Options = QueryHookOptions<FetchMyOffersData>;

type UseFetchMyOffers = (
    options: Options,
) => QueryResult<FetchMyOffersData, {}>;

const useFetchMyOffers: UseFetchMyOffers = (options: Options) => {
    return useQuery(FETCH_MY_OFFERS, options);
};

export default useFetchMyOffers;
