import { useQuery, QueryHookOptions, QueryResult } from '@apollo/client';

import { ConnectedAccountsDataType } from './types';
import { FETCH_CONNECTED_ACCOUNTS } from './gql';

type Options = QueryHookOptions<ConnectedAccountsDataType, {}>;

const useFetchConnectedAccounts = (
    options: Options,
): QueryResult<ConnectedAccountsDataType, {}> =>
    useQuery(FETCH_CONNECTED_ACCOUNTS, options);

export default useFetchConnectedAccounts;
