import { gql } from '@apollo/client';

export const SEND_RESET_PASSWORD_NOTIFICATION = gql`
    mutation SendResetPasswordNotification($email: String!, $brandId: String!) {
        sendResetPasswordNotification(
            input: { email: $email, brandId: $brandId }
        )
            @rest(
                type: "SendResetPasswordNotification"
                path: "/auth/reset-pass-notification"
                method: "POST"
            ) {
            NoResponse
        }
    }
`;

export const RESET_PASSWORD = gql`
    mutation ResetPassword($newPass: String!, $code: String!) {
        resetPassword(input: { newPass: $newPass, code: $code })
            @rest(
                type: "ResetPassword"
                path: "/auth/reset-pass"
                method: "POST"
            ) {
            NoResponse
        }
    }
`;
