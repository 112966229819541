import { gql } from '@apollo/client';

export const FETCH_SHIPPING_ADDRESS = gql`
    query ShippingAddress {
        shippingAddress
            @rest(
                type: "ShippingAddress"
                method: "GET"
                path: "/auth/address"
            ) {
            address1
            address2
            city
            state
            country
            zip
        }
    }
`;

export const SET_SHIPPING_ADDRESS = gql`
    mutation SetShippingAddress($input: JSON!) {
        setShippingAddress(input: $input)
            @rest(
                type: "ShippingAddress"
                path: "/auth/address"
                method: "POST"
            ) {
            address1
            address2
            city
            state
            country
            zip
        }
    }
`;
