import React, { ReactElement, useRef } from 'react';
import {
    Button,
    Flex,
    RRNavLink,
    Text,
    NavLink,
    IconMdLogout,
    ThemeUIStyleObject,
} from '@t2-market-place/ui';
import { dashboardNavDefinition } from '../../DashboardNavDefinition';
import useToggleMenu from '../../useToggleMenu';
import useLogout from '../../useLogout';
import { navButtonSX } from '../_commonSX';
import { useCurrentUserContext } from '../../../../CurrentUserContext';

const dashboardNavSX = {
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderTopWidth: 2,
    borderTopColor: 'neonBlue',
    borderTopStyle: 'solid',
    padding: 3,
    fontSize: 18,
    cursor: 'pointer',
    '&>svg': {
        marginRight: 3,
    },
} as ThemeUIStyleObject;

const ProfileButton: React.FC = (): ReactElement => {
    const currentUser = useCurrentUserContext();
    const menuRef = useRef(null);
    const buttonMenuRef = useRef(null);

    const { closeMenu, isMenuOpen, toggleMenu } = useToggleMenu({
        menuRef,
        buttonMenuRef,
    });

    const logOut = useLogout();

    return (
        <>
            <Button sx={navButtonSX} onClick={toggleMenu} ref={buttonMenuRef}>
                Hi, {currentUser?.firstName}
            </Button>
            {isMenuOpen && (
                <Flex
                    ref={menuRef}
                    sx={{
                        zIndex: 1000,
                        flexDirection: 'column',
                        position: 'absolute',
                        top: 47,
                        right: 0,
                        padding: 3,
                        backgroundColor: 'bgHeaderMenu',
                    }}
                >
                    {dashboardNavDefinition.map(({ label, to, Icon }) => {
                        return (
                            <RRNavLink
                                key={to}
                                to={to}
                                onClick={closeMenu}
                                sx={dashboardNavSX}
                            >
                                <Icon />
                                <Text>{label}</Text>
                            </RRNavLink>
                        );
                    })}
                    <NavLink onClick={logOut} sx={dashboardNavSX}>
                        <IconMdLogout />
                        <Text>Logout</Text>
                    </NavLink>
                </Flex>
            )}
        </>
    );
};

export default ProfileButton;
