import { useMutation, MutationHookOptions } from '@apollo/client';

import { RefreshTokenData } from './types';
import { REFRESH_TOKEN } from './gql';

type Options = MutationHookOptions<RefreshTokenData>;

const useRefreshToken = (options: Options) =>
    useMutation(REFRESH_TOKEN, options);

export default useRefreshToken;
