import {
    useMutation,
    MutationHookOptions,
    MutationTuple,
} from '@apollo/client';

import { RegisterData, RegisterParams } from './types';
import { REGISTER } from './gql';

type Options = MutationHookOptions<RegisterData, RegisterParams>;

const useRegister = (
    options: Options,
): MutationTuple<RegisterData, RegisterParams> =>
    useMutation(REGISTER, options);

export default useRegister;
