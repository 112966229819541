import React, { ReactElement } from 'react';
import { ThemeUIProvider, Theme } from 'theme-ui';

export interface Props {
    children: React.ReactNode;
    theme: Theme;
}

const ThemeProvider: React.FC<Props> = ({
    children,
    theme,
}: Props): ReactElement => (
    <ThemeUIProvider theme={theme}>{children}</ThemeUIProvider>
);

export default ThemeProvider;
