import {
    merge,
    Theme as ThemeUI,
    base,
    ThemeUIStyleObject,
} from '@t2-market-place/ui';

const cosmosBlue = '#023047';
const blueAndroidBase = '#5679BB';
const naturalOrchestra = '#4C987C';
const corbeau = '#111022';
const clearView = '#E2EBE8';
const quietBay = '#6197CF';
const spritzig = '#71C0EB';
const butterflyBlue = '#219EBC';
const whiteSmoke = '#F4F5F5';

interface ExtendedThemeUI extends ThemeUI {
    flex?: Record<string, ThemeUIStyleObject>;
    box?: Record<string, ThemeUIStyleObject>;
}

const theme = merge(
    base as ExtendedThemeUI,
    {
        breakpoints: ['576px', '768px', '992px', '1200px', '1400px'],
        fonts: {
            body: 'OpenSansCondensedSemiBold, sans-serif',
            heading: 'OpenSansCondensedSemiBold',
            monospace: '"Open Sans", sans-serif',
        },
        colors: {
            text: cosmosBlue,
            brandPrimary: naturalOrchestra,
            lightNeonBlue: '#BDE0E8',
            ultraLightNeonBlue: '#F2F7F8',
            neonBlue: naturalOrchestra,
            lightBlue: '#DFF0F4',
            springGreen: naturalOrchestra,
            darkRed: '#B20000',
            greyBG: '#E5EAEC',
            ultraGreyBG: '#F0EFED',
            dugong: '#707070',

            bgHeader: '#ffffff',
            bgHeaderMenu: corbeau,
            bgHomeBanner: blueAndroidBase,
            hamburgerMenuIcon: naturalOrchestra,
            homePageSearchColor: '#37AAE8',
            kodamaWhite: '#E8F5FC',
            gemSilica: '#6CC4A4',
            oGradyGreen: '#5BAB92',
            shiroiWhite: '#E7F5F0',
            blueBay: '#639AD2',
            summerSky: '#37AAE8',
            blueAndroidBase: '#5679BB',
            pumpkinSoup: '#de7c08',
        },
        buttons: {
            primary: {
                backgroundColor: 'brandPrimary',
                fontFamily: 'OpenSansCondensedSemiBold',
                textTransform: 'none',
                color: '#ffffff',
                cursor: 'pointer',
            },
            secondary: {
                textTransform: 'none',
                fontFamily: 'OpenSansCondensedSemiBold',
                backgroundColor: 'neonBlue',
                color: 'white',
            },
            outline: {
                fontFamily: 'OpenSansCondensedSemiBold',
                fontSize: [19, 19, 26, 26, 26],
                textTransform: 'none',
                color: 'neonBlue',
                backgroundColor: 'transparent',
                borderWidth: 2,
                borderColor: 'text',
                borderStyle: 'solid',
                borderRadius: 9,
                cursor: 'pointer',
            },
            accept: {
                textTransform: 'none',
                backgroundColor: 'springGreen',
                color: 'white',
                fontFamily: 'OpenSansCondensedSemiBold',
            },
            decline: {
                textTransform: 'none',
                backgroundColor: 'darkRed',
                color: 'white',
                fontFamily: 'OpenSansCondensedSemiBold',
            },
            profileBigButton: {
                backgroundColor: clearView,
                color: 'text',
            },
            createUploadMedia: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'transparent',
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: spritzig,
                borderRadius: 10,
                color: corbeau,
                fontFamily: 'OpenSansCondensedBold',
            },
            auth: {
                primary: {
                    textTransform: 'none',
                    borderRadius: 15,
                    backgroundColor: 'gemSilica',
                    color: 'white',
                    fontFamily: 'OpenSansCondensedSemiBold',
                    fontSize: 4,
                    paddingY: 1,
                    cursor: 'pointer',
                    '&:hover': {
                        color: 'blueBay',
                    },
                },
                secondary: {
                    borderRadius: 15,
                    backgroundColor: 'blueBay',
                    color: 'white',
                    fontFamily: 'OpenSansCondensedSemiBold',
                    fontSize: 4,
                    paddingY: 1,
                    cursor: 'pointer',
                    '&:hover': {
                        color: 'gemSilica',
                    },
                },
            },
            orange: {
                fontFamily: 'OpenSansCondensedSemiBold',
                backgroundColor: 'pumpkinSoup',
                cursor: 'pointer',
            },
        },
        text: {
            regular: {
                fontFamily: 'OpenSansRegular',
            },
            nonBold: {
                fontFamily: 'OpenSansCondensedRegular',
            },
            propertyKey: {
                color: 'neonBlue',
                fontSize: [12, 12, 18, 18, 18],
                textTransform: 'capitalize',
            },
            propertyValue: {
                fontFamily: 'OpenSansCondensedBold',
                fontSize: [12, 12, 18, 18, 18],
            },
            heading: {
                h2: {
                    fontSize: [19, 19, 34, 34, 34],
                    fontFamily: [
                        'OpenSansCondensedBold',
                        'OpenSansCondensedBold',
                        'OpenSansCondensedBold',
                        'OpenSansCondensedBold',
                        'OpenSansBold',
                    ],
                },
                h3: {
                    fontSize: [19, 19, 26, 26, 26],
                    fontFamily: 'OpenSansCondensedBold',
                    textTransform: 'capitalize',
                },
                h4: {
                    fontSize: 23,
                },
                h5: {
                    fontFamily: 'OpenSansCondensedBold',
                    fontSize: 18,
                },
                createSectionTitle: {
                    fontSize: [19, 19, 34, 34, 34],
                    fontFamily: [
                        'OpenSansCondensedBold',
                        'OpenSansCondensedBold',
                        'OpenSansCondensedBold',
                        'OpenSansCondensedBold',
                        'OpenSansBold',
                    ],
                    color: blueAndroidBase,
                },
                createSubSectionTitle: {
                    textTransform: 'capitalize',
                    fontFamily: 'OpenSansCondensedSemiBold',
                    fontSize: [15, 19, 19, 19, 19],
                    color: corbeau,
                    '&.active': {
                        fontFamily: 'OpenSansCondensed',
                        color: 'text',
                        borderTopWidth: 5,
                        borderTopStyle: 'solid',
                        borderTopColor: spritzig,
                    },
                },
            },
            withPrimaryBG: {
                fontFamily: 'OpenSansCondensedSemiBold',
                backgroundColor: 'brandPrimary',
                color: 'white',
            },
            formError: {
                color: 'darkRed',
            },
            footer: {
                color: 'white',
            },
        },
        links: {
            headNav: {
                color: blueAndroidBase,
                fontSize: 26,
                fontFamily: 'OpenSansCondensedSemiBold',
                '&:hover': {
                    color: naturalOrchestra,
                },
                '&:focus': {
                    color: naturalOrchestra,
                },
            },
            nav: {
                color: 'white',
                fontSize: 26,
                fontFamily: 'OpenSansCondensedSemiBold',
                textTransform: 'uppercase',
                '&:hover': {
                    color: 'brandPrimary',
                },
                '&:focus': {
                    color: 'brandPrimary',
                },
            },
            footerNav: {
                color: 'white',
                textDecoration: 'underline',
                fontFamily: 'OpenSansCondensedSemiBold',
                '&:hover': {
                    color: 'brandPrimary',
                },
                '&:focus': {
                    color: 'brandPrimary',
                },
            },
        },
        badges: {
            primary: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 1,
                paddingX: 2,
                borderRadius: '50%',
                backgroundColor: 'gemSilica',
                color: 'text',
                fontFamily: 'OpenSansCondensedSemiBold',
                fontSize: 11,
            },
        },
        styles: {
            a: {
                textDecoration: 'none',
            },
        },
        forms: {
            label: {
                inputField: {
                    width: '100%',
                    whiteSpace: 'nowrap',
                    paddingX: 2,
                    paddingY: 1,
                    alignItems: 'center',
                    borderColor: butterflyBlue,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderRadius: 10,
                    fontSize: 14,
                    fontWeight: 600,
                    '& > span': {
                        flex: '1 0 auto',
                        padding: 0,
                        margin: 0,
                        textTransform: 'capitalize',
                        color: blueAndroidBase,
                    },
                    '& > input': {
                        paddingY: 0,
                        marginY: 0,
                        border: 'none',
                        fontSize: 18,
                        fontFamily: 'OpenSansCondensedExtraBold',
                        fontWeight: 'bold',
                        outline: 'none',
                        flex: '1 1 auto',
                        width: 'inherit',
                        WebkitAppearance: 'none',
                        MozAppearance: 'textfield',

                        '&::-webkit-inner-spin-button': {
                            WebkitAppearance: 'none',
                            MozAppearance: 'textfield',
                        },
                    },
                    '& > div': {
                        flex: '1 1 auto',
                        paddingX: 1,
                        '& > select': {
                            fontWeight: 800,
                            fontSize: 18,
                            padding: 0,
                            outline: 'none',
                            border: 'unset',
                        },
                    },
                    '& > svg': {
                        color: 'neonBlue',
                        cursor: 'pointer',
                    },
                },
                detailsInputField: {
                    alignItems: 'center',
                    marginY: 2,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: spritzig,
                    borderRadius: 10,
                    '&>input,&>textarea': {
                        border: 'none',
                        outline: 'none',
                        color: 'text',
                        fontSize: 16,
                        fontFamily: 'OpenSansCondensedBold',
                    },
                    '&>span': {
                        textTransform: 'capitalize',
                        color: 'neonBlue',
                        minWidth: '25%',
                        textAlign: 'right',
                        marginX: 2,
                    },
                },
                signup: {
                    flexDirection: ['column', 'column', 'column', 'row', 'row'],
                    alignItems: [
                        'center',
                        'center',
                        'center',
                        'center',
                        'center',
                    ],
                    '& > span': {
                        marginRight: ['unset', 'unset', 'unset', 2, 2],
                        flex: '1 0 auto',
                        fontFamily: 'OpenSansCondensedSemiBold',
                        fontSize: 4,
                    },
                    '& input': {
                        minWidth: 280,
                        borderRadius: 0,
                        borderWidth: 1,
                        borderColor: 'dugong',
                        borderStyle: 'solid',
                        backgroundColor: 'white',
                        fontFamily: 'OpenSansCondensedSemiBold',
                        '&:focus-visible': {
                            outline: 'none',
                        },
                    },
                },
                login: {
                    fontFamily: 'OpenSansCondensedSemiBold',
                    fontSize: 4,
                },
            },
            input: {
                login: {
                    borderRadius: 0,
                    borderWidth: 1,
                    borderColor: 'dugong',
                    borderStyle: 'solid',
                    backgroundColor: 'white',
                    fontFamily: 'OpenSansCondensedSemiBold',
                    '&:focus-visible': {
                        outline: 'none',
                    },
                },
            },
        },
        flex: {
            footer: {
                background: corbeau,
            },
            dashboardNav: {
                color: 'text',
                backgroundColor: clearView,
            },
            dashboardNavActive: {
                color: 'white',
                backgroundColor: quietBay,
            },
            categorySelection: {
                backgroundColor: corbeau,
                color: 'white',
                opacity: 0.5,
            },
            categorySelectionActive: {
                backgroundColor: corbeau,
                color: 'white',
                opacity: 1,
            },
            createSectionHeader: {
                background: `linear-gradient(0deg, transparent 45%, ${spritzig} 50%, transparent 51%)`,
                '& span': {
                    fontFamily: 'OpenSansCondensedBold',
                    color: spritzig,
                },
                '& span.active': {
                    color: 'text',
                    display: 'unset',
                },
            },
            createSectionFieldTitle: {
                '&>svg': {
                    color: spritzig,
                },
            },
            createFeeToolTip: {
                backgroundColor: naturalOrchestra,
                borderColor: corbeau,
                color: 'white',
            },
            currenciesSelection: {
                backgroundColor: 'brandPrimary',
                color: 'white',
                opacity: 0.5,
                fontFamily: 'OpenSansCondensedExtraBold',
                '&.active': {
                    opacity: 1,
                },
            },
            createDetailsWithShipping: {
                borderColor: spritzig,
                color: corbeau,
                '& .option': {
                    textTransform: 'uppercase',
                    fontFamily: 'OpenSansCondensedBold',
                    fontSize: ['10px', 16, 16, 16, 16],
                    minWidth: [60, 90, 90, 90, 90],
                    textAlign: 'center',
                    backgroundColor: 'brandPrimary',
                    color: 'white',
                    opacity: 0.5,
                    paddingY: 1,
                    borderRadius: 7,
                    cursor: 'pointer',
                },
                '& .option.active': {
                    cursor: 'default',
                    opacity: 1,
                },
            },
            createExchange: {
                borderColor: spritzig,
                color: corbeau,
                '& .option': {
                    textTransform: 'uppercase',
                    fontFamily: 'OpenSansCondensedBold',
                    fontSize: ['10px', 16, 16, 16, 16],
                    minWidth: [60, 90, 90, 90, 90],
                    textAlign: 'center',
                    backgroundColor: 'brandPrimary',
                    color: 'white',
                    opacity: 0.5,
                    paddingY: 1,
                    borderRadius: 7,
                    cursor: 'pointer',
                },
                '& .option.active': {
                    cursor: 'default',
                    opacity: 1,
                },
            },
            createExchangeExpirationPeriod: {
                borderColor: 'neonBlue',
                color: corbeau,
                '& .option': {
                    fontFamily: 'OpenSansCondensedBold',
                    fontSize: ['10px', 16, 16, 16, 16],
                    minWidth: [35, 90, 90, 90, 90],
                    backgroundColor: 'brandPrimary',
                    textAlign: 'center',
                    color: 'white',
                    opacity: 0.6,
                    paddingY: 1,
                    borderRadius: 7,
                    cursor: 'pointer',
                },
                '& input': {
                    fontFamily: 'OpenSansCondensedBold',
                    color: corbeau,
                    outline: 'none',
                    border: 'none',
                },
                '& .option.active': {
                    cursor: 'default',
                    opacity: 1,
                },
            },
            tableBodyRow: {
                backgroundColor: 'transparent',
                '&.marked': {
                    backgroundColor: whiteSmoke,
                },
            },
            detailsPriceFee: {
                backgroundColor: clearView,
            },
            offerForListing: {
                backgroundColor: corbeau,
                '& .body': {
                    backgroundColor: 'white',
                },
                '& .item': {
                    borderColor: clearView,
                },

                '& .marked': {
                    backgroundColor: clearView,
                },
            },
        },
        box: {
            tableHeader: {
                backgroundColor: corbeau,
            },
            boxDashedSX: {
                borderColor: corbeau,
            },
        },
    } as ExtendedThemeUI,
);

export type Theme = typeof theme;
export default theme;
