import {
    useMutation,
    MutationHookOptions,
    MutationTuple,
} from '@apollo/client';

import { UPLOAD_FILES_MUTATION } from './gql';
import { UploadFilesDataType, UploadFilesParams } from './types';

type Options = MutationHookOptions<UploadFilesDataType, UploadFilesParams>;
type UseUploadFiles = (
    options: Options,
) => MutationTuple<UploadFilesDataType, UploadFilesParams>;

const useUploadImages: UseUploadFiles = (options) => {
    return useMutation(UPLOAD_FILES_MUTATION, options);
};

export default useUploadImages;
