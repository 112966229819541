import { ViewProps } from '@t2-market-place/view-default';

import imgBalanceBG from './balanceBG.png';
import imgLogoPoweredByT2TKN from './LogoPoweredByT2TKN.png';
import imgT2LogoCoin from './T2Logo-Coin.png';
import imgT2LogoFooter from './T2Logo-Footer.png';
import imgT2LogoLong from './T2Logo-Long.png';

const brandImages: ViewProps['brandImages'] = {
    imgBalanceBG,
    imgLogoPoweredByT2TKN,
    imgT2LogoCoin,
    imgT2LogoFooter,
    imgT2LogoLong,
};

export default brandImages;
