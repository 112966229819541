import React from 'react';
import { Box, Flex, RRNavLink } from '@t2-market-place/ui';
import { routesDefinitions } from '../../../../Routes/routesDefinitions';

import Logo from '../../Logo';
import AuthButtons from './AuthButtons';
import LoginDialogWrapper from '../../../Login/LoginDialogWrapper';
import { useAppConstContext } from '../../../useAppConst';

const DesktopNav: React.FC = (): JSX.Element => {
    const { HEADER_HEIGHT, MAX_WIDTH_DESKTOP_PAGE } = useAppConstContext();
    return (
        <Box
            sx={{
                display: ['none', 'none', 'none', 'none', 'flex'],
                backgroundColor: 'bgHeader',
                position: 'absolute',
                top: 0,
                width: '100%',
                height: HEADER_HEIGHT,
            }}
        >
            <Flex
                sx={{
                    marginX: 'auto',
                    maxWidth: MAX_WIDTH_DESKTOP_PAGE,
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingX: [3, 3, 5, 5, 5],
                }}
            >
                <Logo />
                <Flex
                    sx={{
                        flex: 1,
                        marginX: 4,
                    }}
                >
                    <RRNavLink
                        variant={'headNav'}
                        to={routesDefinitions.HOW_IT_WORKS}
                        marginX={3}
                    >
                        How It Works
                    </RRNavLink>
                    <RRNavLink
                        marginX={3}
                        variant={'headNav'}
                        to={routesDefinitions.ABOUT_US}
                    >
                        About
                    </RRNavLink>
                </Flex>
                <Flex sx={{ position: 'relative', alignItems: 'center' }}>
                    <LoginDialogWrapper>
                        <RRNavLink
                            marginX={3}
                            variant={'headNav'}
                            to={routesDefinitions.LISTING_CREATE}
                        >
                            Sell My Item
                        </RRNavLink>
                    </LoginDialogWrapper>
                    <Flex>
                        <AuthButtons />
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    );
};

export default DesktopNav;
