import React from 'react';
import { Flex } from '@t2-market-place/ui';
import { useAppConstContext } from '../useAppConst';

import Header from './Header';
import Footer from './Footer';

interface Props {
    children?: React.ReactNode;
}
const PageWrapper: React.FC<Props> = ({ children }): JSX.Element => {
    const { PAGE_PADDING_BOTTOM, PAGE_PADDING_TOP } = useAppConstContext();

    return (
        <Flex
            sx={{
                flexDirection: 'column',
                width: '100%',
                position: 'relative',
                minHeight: '100vh',
                paddingBottom: PAGE_PADDING_BOTTOM,
                paddingTop: PAGE_PADDING_TOP,
            }}
        >
            <Header />
            {children}
            <Footer />
        </Flex>
    );
};

export default PageWrapper;
