import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';
import { FETCH_LISTING_FEE } from './gql';
import { FetchListingFeeData, FetchListingFeeParams } from './types';

type Options = QueryHookOptions<FetchListingFeeData, FetchListingFeeParams>;

type UseFetchListingFee = (
    options: Options,
) => QueryResult<FetchListingFeeData, FetchListingFeeParams>;

const useFetchListingFee: UseFetchListingFee = (options: Options) => {
    return useQuery(FETCH_LISTING_FEE, options);
};

export default useFetchListingFee;
