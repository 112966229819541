import {
    useMutation,
    MutationHookOptions,
    MutationTuple,
} from '@apollo/client';

import { PasswordChangeData, PasswordChangeParams } from './types';
import { PASSWORD_CHANGE } from './gql';

type Options = MutationHookOptions<PasswordChangeData, PasswordChangeParams>;

const usePasswordChange = (
    options: Options,
): MutationTuple<PasswordChangeData, PasswordChangeParams> =>
    useMutation(PASSWORD_CHANGE, options);

export default usePasswordChange;
