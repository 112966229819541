import { useQuery, QueryHookOptions, QueryResult } from '@apollo/client';

import { FETCH_OPEN_CONTRACTS } from './gql';
import { FetchOpenContractsData } from './types';

type Options = QueryHookOptions<FetchOpenContractsData>;

type UseFetchOpenSmartContracts = (
    options: Options,
) => QueryResult<FetchOpenContractsData, {}>;

const useFetchOpenSmartContracts: UseFetchOpenSmartContracts = (
    options: Options,
) => {
    return useQuery(FETCH_OPEN_CONTRACTS, options);
};

export default useFetchOpenSmartContracts;
