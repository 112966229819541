import React, { ReactElement } from 'react';
import { BrowserRouter } from 'react-router-dom';

import {
    ApolloProvider,
    AuthContextProvider,
    ConnectedAccountsContextProvider,
} from '@t2-market-place/state';
import { ThemeProvider, Theme } from '@t2-market-place/ui';

import {
    AppConstContextProvider,
    AppConstContextProps,
} from './_Common/Components/useAppConst';
import {
    BrandImagesContextProvider,
    BrandImagesContextProps,
} from './_Common/Components/useBrandImages';

import Routes from './Routes';
import { CurrentUserContextProvider } from './_Common/Components/CurrentUserContext';

export interface ViewProps {
    appConst: AppConstContextProps;
    brandImages: BrandImagesContextProps;
    theme: Theme;
}
const View: React.FC<ViewProps> = ({
    appConst,
    brandImages,
    theme,
}): ReactElement => (
    <AuthContextProvider>
        <ApolloProvider>
            <ConnectedAccountsContextProvider>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <AppConstContextProvider appConst={appConst}>
                            <BrandImagesContextProvider
                                brandImages={brandImages}
                            >
                                <CurrentUserContextProvider>
                                    <Routes />
                                </CurrentUserContextProvider>
                            </BrandImagesContextProvider>
                        </AppConstContextProvider>
                    </BrowserRouter>
                </ThemeProvider>
            </ConnectedAccountsContextProvider>
        </ApolloProvider>
    </AuthContextProvider>
);

export default View;
