import { useMutation, MutationHookOptions } from '@apollo/client';

import { DeleteListingData, DeleteListingParams } from './types';
import { DELETE_LISTING } from './gql';

type Options = MutationHookOptions<DeleteListingData, DeleteListingParams>;

const useDeleteListing = (options: Options) =>
    useMutation(DELETE_LISTING, {
        ...options,
        ...{
            update: (cache, response, { variables }) => {
                cache.modify({
                    fields: {
                        fetchMyListings: (existing, { readField }) => {
                            // @ts-ignore
                            return existing.filter((listing) => {
                                return (
                                    variables?.id !== readField('id', listing)
                                );
                            });
                        },
                    },
                });
            },
        },
    });

export default useDeleteListing;
