import {
    useMutation,
    MutationHookOptions,
    MutationTuple,
} from '@apollo/client';

import { CreateListingData, CreateListingParams } from './types';
import { CREATE_LISTING } from './gql';

type Options = MutationHookOptions<CreateListingData, CreateListingParams>;

const useCreateListing = (
    options: Options,
): MutationTuple<CreateListingData, CreateListingParams> =>
    useMutation(CREATE_LISTING, options);

export default useCreateListing;
