import { useMutation } from '@apollo/client';

import { CREATE_LISTING_COMMENT, FETCH_COMMENTS_FOR_LISTING } from './gql';
import { CreateListingCommentData, CreateListingCommentParams } from './types';

interface Options {
    listingId: string;
}
const useCreateListingComment = ({ listingId }: Options) => {
    return useMutation<CreateListingCommentData, CreateListingCommentParams>(
        CREATE_LISTING_COMMENT,
        {
            refetchQueries: [
                {
                    query: FETCH_COMMENTS_FOR_LISTING,
                    variables: {
                        id: listingId,
                    },
                },
            ],
            awaitRefetchQueries: true,
        },
    );
};

export default useCreateListingComment;
