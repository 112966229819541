import {
    useMutation,
    MutationHookOptions,
    MutationTuple,
} from '@apollo/client';

import { SetDefaultCurrencyData, SetDefaultCurrencyParams } from './types';
import { SET_DEFAULT_CURRENCY } from './gql';

type Options = MutationHookOptions<
    SetDefaultCurrencyData,
    SetDefaultCurrencyParams
>;

type UseSetDefaultCurrency = (
    options: Options,
) => MutationTuple<SetDefaultCurrencyData, SetDefaultCurrencyParams>;
const useSetDefaultCurrency: UseSetDefaultCurrency = (options) =>
    useMutation(SET_DEFAULT_CURRENCY, options);

export default useSetDefaultCurrency;
