import React from 'react';

import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';

const Header: React.FC = (): JSX.Element => {
    return (
        <>
            <DesktopNav />
            <MobileNav />
        </>
    );
};

export default Header;
