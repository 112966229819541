import {
    useMutation,
    MutationHookOptions,
    MutationTuple,
} from '@apollo/client';

import { CROP_IMAGE } from './gql';
import { CropImageDataType, CropImageParams } from './types';

type Options = MutationHookOptions<CropImageDataType, CropImageParams>;
type UseCropImage = (
    options: Options,
) => MutationTuple<CropImageDataType, CropImageParams>;

const useCropImage: UseCropImage = (options) => {
    return useMutation(CROP_IMAGE, options);
};

export default useCropImage;
