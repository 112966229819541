import {
    IconType,
    IconMdAccountCircle,
    IconFaFolderOpen,
    IconFaListUl,
    IconMdOutlineFileCopy,
} from '@t2-market-place/ui';
import { routesDefinitions } from '../../../../Routes/routesDefinitions';

import imgT2TknYellow from './T2TknYellow.png';

interface DashboardNavDefinition {
    label: string;
    to: string;
    Icon: IconType;
    img?: string;
}
export const dashboardNavDefinition: DashboardNavDefinition[] = [
    {
        label: 'My Profile',
        to: routesDefinitions.DASHBOARD_MY_PROFILE,
        Icon: IconMdAccountCircle,
    },
    {
        label: 'My Offers',
        to: routesDefinitions.DASHBOARD_MY_OFFERS,
        Icon: IconFaFolderOpen,
    },
    {
        label: 'My Listings',
        to: routesDefinitions.DASHBOARD_MY_LISTINGS,
        Icon: IconFaListUl,
    },
    {
        label: 'Open Contracts',
        to: routesDefinitions.DASHBOARD_OPEN_CONTRACTS,
        Icon: IconMdOutlineFileCopy,
        img: imgT2TknYellow,
    },
];
