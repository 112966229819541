import { gql } from '@apollo/client';

export const REGISTER = gql`
    mutation Register(
        $login: String!
        $pass: String!
        $firstName: String!
        $lastName: String!
        $address: String!
        $phone: String!
        $email: String!
        $currency: String
        $registerT2: Boolean!
        $brandId: String!
    ) {
        register(
            input: {
                login: $login
                pass: $pass
                firstName: $firstName
                lastName: $lastName
                address: $address
                phone: $phone
                email: $email
                currency: $currency
                registerT2: $registerT2
                brandId: $brandId
            }
        ) @rest(type: "register", path: "/auth/register", method: "POST") {
            NoResponse
        }
    }
`;
