export const routesDefinitions = {
    HOME: '/',

    LISTING_CREATE: '/listing/create',
    LISTING_DETAILS: '/details/:id',

    DASHBOARD_MY_PROFILE: '/dashboard/my-profile',
    DASHBOARD_MY_OFFERS: '/dashboard/my-offers',
    DASHBOARD_MY_LISTINGS: '/dashboard/my-listings',
    DASHBOARD_OPEN_CONTRACTS: '/dashboard/open-contracts',

    ABOUT_US: '/about-us',
    HOW_IT_WORKS: '/how-it-works',
    LOGIN: '/login',
    SIGNUP: '/signup',
    FORGOTTEN_PASSWORD: '/forgotten-password',
    RESET_PASSWORD: '/reset-password/:code',
    T2_CONNECTION: '/t2-connection',
    NOTIFICATION: '/notification',
    VERIFICATION: '/verification/:verificationId',
    TERMS_AND_CONDITIONS: '/terms-and-conditions',
};
