import React from 'react';
import {
    Box,
    Flex,
    Image,
    RRNavLink,
    Link,
    Text,
} from '../../../../../../ui/src';
import { routesDefinitions } from '../../../Routes/routesDefinitions';
import { useAppConstContext } from '../../useAppConst';
import { useBrandImagesContext } from '../../useBrandImages';
import useInfoEmail from '../../useInfoEmail';
import LoginDialogWrapper from '../../Login/LoginDialogWrapper';

import cryptoducationPartnerIcon from './cryptoducationPartnerIcon.png';

const Footer: React.FC = (): JSX.Element => {
    const { imgT2LogoFooter } = useBrandImagesContext();
    const { FOOTER_HEIGHT, MAX_WIDTH_DESKTOP_PAGE } = useAppConstContext();
    const infoEmail = useInfoEmail();
    return (
        <Flex
            variant={'flex.footer'}
            sx={{
                position: 'absolute',
                bottom: 0,
                height: FOOTER_HEIGHT,
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Flex
                sx={{
                    flexDirection: [
                        'column-reverse',
                        'row',
                        'row',
                        'row',
                        'row',
                    ],
                    alignItems: 'center',
                    width: '100%',
                    paddingX: [3, 3, 5, 5, 5],
                    maxWidth: MAX_WIDTH_DESKTOP_PAGE,
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: ['none', 'none', 'none', 'none', 'flex'],
                        flexDirection: 'column',
                    }}
                >
                    <LoginDialogWrapper>
                        <RRNavLink
                            marginBottom={1}
                            variant={'links.footerNav'}
                            to={routesDefinitions.LISTING_CREATE}
                        >
                            Sell My Item
                        </RRNavLink>
                    </LoginDialogWrapper>
                    <RRNavLink
                        marginBottom={1}
                        variant={'links.footerNav'}
                        to={routesDefinitions.ABOUT_US}
                    >
                        About
                    </RRNavLink>
                    <RRNavLink
                        marginBottom={1}
                        variant={'links.footerNav'}
                        to={routesDefinitions.HOW_IT_WORKS}
                    >
                        How It Works
                    </RRNavLink>
                    <RRNavLink
                        variant={'links.footerNav'}
                        to={routesDefinitions.TERMS_AND_CONDITIONS}
                    >
                        Terms And Conditions
                    </RRNavLink>
                </Box>
                <Flex
                    sx={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Link href={'https://www.t2tkn.com/'} target={'_blank'}>
                        <Image
                            alt={'logo'}
                            sx={{ maxWidth: 200 }}
                            src={imgT2LogoFooter}
                        />
                    </Link>
                </Flex>
                <Flex
                    sx={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: [
                            'column-reverse',
                            'column-reverse',
                            'column-reverse',
                            'row',
                            'row',
                        ],
                    }}
                >
                    <Link
                        href={'https://cryptoducation.com/'}
                        target={'_blank'}
                    >
                        <Image
                            alt={'Cryptoducation Partner'}
                            sx={{
                                maxWidth: 200,
                                marginY: [2, 2, 2, 'unset', 'unset'],
                            }}
                            src={cryptoducationPartnerIcon}
                        />
                    </Link>
                    <Flex
                        sx={{
                            flex: 1,
                            flexDirection: 'column',
                            alignItems: [
                                'center',
                                'flex-end',
                                'flex-end',
                                'flex-end',
                                'flex-end',
                            ],
                            justifyContent: 'flex-start',
                            marginY: [3, 'unset', 'unset', 'unset', 'unset'],
                        }}
                    >
                        <Text
                            variant={`footer`}
                            sx={{
                                textTransform: 'none',
                                fontSize: 'unset',
                            }}
                        >
                            Any suggestions/feedback?
                        </Text>
                        <Text
                            variant={`footer`}
                            sx={{
                                textTransform: 'none',
                                fontSize: 'unset',
                            }}
                        >
                            Please contact us at
                        </Text>
                        <Link
                            variant={`links.nav`}
                            href={`mailto:${infoEmail}`}
                            sx={{
                                textTransform: 'none',
                                fontSize: 'unset',
                            }}
                        >
                            {infoEmail}
                        </Link>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default Footer;
