import {
    useMutation,
    MutationHookOptions,
    MutationTuple,
} from '@apollo/client';

import { UnPublishListingData, UnPublishListingParams } from './types';
import { UNPUBLISH_LISTING } from './gql';

type Options = MutationHookOptions<
    UnPublishListingData,
    UnPublishListingParams
>;

const useUnPublishListing = (
    options: Options,
): MutationTuple<UnPublishListingData, UnPublishListingParams> =>
    useMutation(UNPUBLISH_LISTING, options);

export default useUnPublishListing;
