import { useCallback } from 'react';
import { useApolloClient, useAuthContext } from '@t2-market-place/state';

const useLogout = () => {
    const { setToken } = useAuthContext();
    const client = useApolloClient();
    const logOut = useCallback(() => {
        client.clearStore().then(() => setToken(undefined));
    }, [setToken, client]);
    return logOut;
};
export default useLogout;
