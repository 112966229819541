import { useQuery, QueryHookOptions, QueryResult } from '@apollo/client';

import { FETCH_MY_LISTINGS } from './gql';
import { FetchMyListingsData } from './types';

type Options = QueryHookOptions<FetchMyListingsData>;

type UseFetchMyListings = (
    options: Options,
) => QueryResult<FetchMyListingsData, {}>;

const useFetchMyListings: UseFetchMyListings = (options: Options) => {
    return useQuery(FETCH_MY_LISTINGS, options);
};

export default useFetchMyListings;
