import { CurrencyType } from '../Currency';

export enum MileageUnits {
    Miles = 'Miles',
    KM = 'KM',
}

export interface MediaFileType {
    id: string;
    file: {
        url: string;
        width: number | null;
        height: number | null;
    };
    originalFileName: string;
    mimeType: string;
    uploaded: string;
    variants?: {
        crop?: {
            url: string;
        };
    };
}

export enum SmartContractStatus {
    IN_DRAFT_SELLERS_PROCESS = 'IN_DRAFT_SELLERS_PROCESS',
    IN_DRAFT_BUYERS_PROCESS = 'IN_DRAFT_BUYERS_PROCESS',
    PENDING_REVIEW = 'PENDING_REVIEW',
    IN_REVIEW = 'IN_REVIEW',
    AMENDMENT_REQUEST = 'AMENDMENT_REQUEST',
    AMENDMENT_REVIEW = 'AMENDMENT_REVIEW',
    TWO_SIGNATURES_SELLERS_PROCESS = 'TWO_SIGNATURES_SELLERS_PROCESS',
    TWO_SIGNATURES_BUYERS_PROCESS = 'TWO_SIGNATURES_BUYERS_PROCESS',
    NEW = 'NEW',
    FUNDS_DEPOSITED_SELLERS_PROCESS = 'FUNDS_DEPOSITED_SELLERS_PROCESS',
    FUNDS_DEPOSITED_BUYERS_PROCESS = 'FUNDS_DEPOSITED_BUYERS_PROCESS',
    PENDING_SHIPPING = 'PENDING_SHIPPING',
    PENDING_EXCHANGE = 'PENDING_EXCHANGE',
    IN_TRANSIT = 'IN_TRANSIT',
    DELIVERED = 'DELIVERED',
    IN_FINAL_REVIEW = 'IN_FINAL_REVIEW',

    RETURN_PENDING = 'RETURN_PENDING',
    IN_RETURN_TRANSIT = 'IN_RETURN_TRANSIT',
    RETURNED = 'RETURNED',
    ARBITRATION = 'ARBITRATION',
    TERMINATED = 'TERMINATED',
    CLOSED = 'CLOSED',
    VERIFIED = 'VERIFIED',
    ARBITRATION_CLOSED = 'ARBITRATION_CLOSED',
}

export enum SmartContractTypeProperty {
    CAR = 'car',
    CAMERA = 'camera',
    COMPUTER = 'computer',
    WATCH = 'watch',
    JEWELRY = 'jewelry',
    OTHER = 'other',
}

export interface SmartContractVehiclesDataType {
    make?: string;
    year?: number;
    model?: string;
    vin?: string;
    mileage?: number;
    mileageUnits?: MileageUnits;
    spareParts?: string;
    aesthetic?: string;
    mechanical?: string;
    turnKey?: boolean;
}
export interface SmartContractCameraDataType {
    make?: string;
    model?: string;
    shutterCount?: number;
    conditions?: string;
    extras?: string;
}
export interface SmartContractComputerDataType {
    make?: string;
    model?: string;
    extras?: string;
    conditions?: string;
}
export interface SmartContractWatchDataType {
    make?: string;
    model?: string;
    serial?: string;
    extras?: string;
    conditions?: string;
}
export interface SmartContractJewelryDataType {
    title?: string;
    extras?: string;
    conditions?: string;
}
export interface SmartContractOtherDataType {
    title?: string;
    extras?: string;
    conditions?: string;
}

export interface SmartContractType {
    id: string;
    type: SmartContractTypeProperty;
    status: SmartContractStatus;
    created: string;
    seller: string;
    buyer?: string;
    images?: MediaFileType[];
    coverId?: string;
    videos?: MediaFileType[];
    data:
        | SmartContractVehiclesDataType
        | SmartContractCameraDataType
        | SmartContractComputerDataType
        | SmartContractWatchDataType
        | SmartContractJewelryDataType
        | SmartContractOtherDataType;
    isReviewer: boolean;
    listingId?: string;
    contractName: string;
}

export interface FetchOpenContractsData {
    openContracts: SmartContractType[];
}
export interface FetchBalanceData {
    balance: {
        balance: number;
        baseBalance: number;
        currency: CurrencyType;
    }[];
}

export enum RedirectURLType {
    T2BUY = 't2buy',
    WALLET = 'wallet',
    PROFILE = 'profile',
    KYC = 'kyc',
}

export interface CreateRedirectURLParams {
    input: {
        type: RedirectURLType;
        params?: object;
    };
}

export interface CreateRedirectURLData {
    createRedirectionURL: {
        url: string;
    };
}

export interface FetchLoginURLData {
    loginURL: {
        url: string;
    };
}
