import {
    useMutation,
    MutationHookOptions,
    MutationTuple,
} from '@apollo/client';

import { DisconnectAccountData, DisconnectAccountParams } from './types';
import { DISCONNCET_ACCOUNT, FETCH_CONNECTED_ACCOUNTS } from './gql';

type Options = MutationHookOptions<
    DisconnectAccountData,
    DisconnectAccountParams
>;

const useDisconnectAccount = (
    options: Options,
): MutationTuple<DisconnectAccountData, DisconnectAccountParams> =>
    useMutation(DISCONNCET_ACCOUNT, {
        ...options,
        refetchQueries: [{ query: FETCH_CONNECTED_ACCOUNTS }],
    });

export default useDisconnectAccount;
