import React, { useCallback, useEffect, useState } from 'react';

const useToggleMenu = ({
    menuRef,
    buttonMenuRef,
}: {
    menuRef: React.MutableRefObject<any>;
    buttonMenuRef: React.MutableRefObject<any>;
}): { toggleMenu: () => void; closeMenu: () => void; isMenuOpen: boolean } => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const toggleMenu = useCallback(() => {
        setIsMenuOpen((v) => !v);
    }, [setIsMenuOpen]);

    const closeMenu = useCallback(() => {
        setIsMenuOpen(false);
    }, [setIsMenuOpen]);

    useEffect(() => {
        function handleClickOutside(event: Event) {
            if (
                isMenuOpen &&
                menuRef.current &&
                // @ts-ignore
                !menuRef.current.contains(event.target) &&
                // @ts-ignore
                !buttonMenuRef.current.contains(event.target)
            ) {
                setIsMenuOpen(false);
            }
        }
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, false);
        };
    }, [menuRef, buttonMenuRef, setIsMenuOpen, isMenuOpen]);

    return {
        isMenuOpen,
        toggleMenu,
        closeMenu,
    };
};

export default useToggleMenu;
