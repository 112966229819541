import { useMutation } from '@apollo/client';

import { CREATE_OFFER_COMMENT, FETCH_COMMENTS_FOR_OFFER } from './gql';
import { CreateOfferCommentData, CreateOfferCommentParams } from './types';

interface Options {
    offerId: string;
}
const useCreateOfferComment = ({ offerId }: Options) => {
    return useMutation<CreateOfferCommentData, CreateOfferCommentParams>(
        CREATE_OFFER_COMMENT,
        {
            refetchQueries: [
                {
                    query: FETCH_COMMENTS_FOR_OFFER,
                    variables: {
                        id: offerId,
                    },
                },
            ],
            awaitRefetchQueries: true,
        },
    );
};

export default useCreateOfferComment;
