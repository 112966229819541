import { MediaFileType } from '../Files';
import { CurrencyType } from '../Currency';

export enum MileageUnits {
    Miles = 'Miles',
    KM = 'KM',
}

export enum FeePayerType {
    buyer = 'buyer',
    seller = 'seller',
    fiftyFifty = '50/50',
}

export enum ExpirationPeriodType {
    DAYS = 'd',
    HOURS = 'h',
}

export enum ListingTypeProperty {
    CAR = 'car',
    CAMERA = 'camera',
    COMPUTER = 'computer',
    WATCH = 'watch',
    JEWELRY = 'jewelry',
    OTHER = 'other',
}

export enum ListingStatus {
    IN_DRAFT = 'IN_DRAFT',
    PENDING = 'PENDING',
    LIVE = 'LIVE',
    OFFER_ACCEPTED = 'OFFER_ACCEPTED',
    ARCHIVE = 'ARCHIVE',
}
export enum ListingValidationProperty {
    IN_PERSON = 'inperson',
    SHIPPING = 'shipping',
    EITHER = 'either',
}

export interface ListingVehiclesDataType {
    make?: string;
    year?: number;
    model?: string;
    vin?: string;
    mileage?: number;
    mileageUnits?: MileageUnits;
    spareParts?: string;
    aesthetic?: string;
    mechanical?: string;
    turnKey?: boolean;
}
export interface ListingCameraDataType {
    make?: string;
    model?: string;
    shutterCount?: number;
    conditions?: string;
    extras?: string;
}
export interface ListingComputerDataType {
    make?: string;
    model?: string;
    extras?: string;
    conditions?: string;
}
export interface ListingWatchDataType {
    make?: string;
    model?: string;
    serial?: string;
    extras?: string;
    conditions?: string;
}
export interface ListingJewelryDataType {
    title?: string;
    extras?: string;
    conditions?: string;
}
export interface ListingOtherDataType {
    title?: string;
    extras?: string;
    conditions?: string;
}

export interface Listing {
    id: string;
    userId: string;
    status: ListingStatus;
    type?: ListingTypeProperty;
    price?: number;
    currency?: CurrencyType;
    pairingCurrencies?: CurrencyType[];
    expirationPeriod?: number;
    expirationPeriodType?: ExpirationPeriodType;
    media?: MediaFileType[];
    coverId?: string;
    fee?: number;
    feePayer?: FeePayerType;
    validation?: ListingValidationProperty;
    shippingIncluded?: boolean;
    title?: string;
    summary?: string;
    location?: string;
    data:
        | ListingVehiclesDataType
        | ListingCameraDataType
        | ListingComputerDataType
        | ListingWatchDataType
        | ListingJewelryDataType
        | ListingOtherDataType;
    contractId?: string;
    contractName: string;
}

export interface CreateListingInputParams {
    type?: ListingTypeProperty;
    price?: number;
    currency?: string;
    pairingCurrencies?: string[];
    expirationPeriod?: number;
    expirationPeriodType?: ExpirationPeriodType;
    media?: string[];
    coverId?: string;
    feePayer?: FeePayerType;
    withShipping?: boolean;
    title?: string;
    summary?: string;
    location?: string;
    data:
        | ListingVehiclesDataType
        | ListingCameraDataType
        | ListingComputerDataType
        | ListingWatchDataType
        | ListingJewelryDataType
        | ListingOtherDataType;
}
export interface CreateListingParams {
    input: CreateListingInputParams;
}

export interface CreateListingData {
    createListing: {
        id: string;
        type: ListingTypeProperty;
        data: Listing['data'];
    };
}

export interface UpdateListingParams {
    id: string;
    input: CreateListingInputParams;
}
export interface UpdateListingData {
    updateListing: Listing;
}

export interface PublishListingParams {
    id: string;
}
export interface PublishListingData {
    publishListing: {
        NoResponse: null;
    };
}

export interface UnPublishListingParams {
    id: string;
}
export interface UnPublishListingData {
    unPublishListing: Listing;
}

export interface DeleteListingParams {
    id: string;
}
export interface DeleteListingData {
    deleteListing: {
        NoResponse: null;
    };
}

export interface FetchListingByIdParams {
    id: string;
}
export interface FetchListingByIdData {
    listingById: Listing;
}

export interface FetchLiveListingsData {
    liveListings: Listing[];
}

export interface FetchListingFeeParams {
    price: number;
    currency: string;
}
export interface FetchListingFeeData {
    fetchListingFee: {
        fee: number;
    };
}

export interface MyListing extends Listing {
    numOffers: number;
}

export interface FetchMyListingsData {
    fetchMyListings: MyListing[];
}

export interface AcceptListingOfferParams {
    listingId: string;
    offerId: string;
    input: {
        shippingData?: {
            cost?: number;
            validation?: ListingValidationProperty;
        };
    };
}
export interface AcceptListingOfferData {
    acceptListingOffer: Listing;
}

export interface FetchRelatedListingsParams {
    id: string;
}

export interface FetchRelatedListingsData {
    relatedListings: Listing[];
}

export interface FetchFilteredLiveListingsParams {
    brandId: string;
    input: {
        type?: ListingTypeProperty;
        priceFrom?: number;
        priceTo?: number;
        currency?: string;
        search?: string;
    };
}

export interface FetchFilteredLiveListingsData {
    filteredLiveListings: Listing[];
}
