import React, { ReactElement } from 'react';
import { Text } from '@t2-market-place/ui';
import { useCurrentUserContext } from '../../../CurrentUserContext';

const ProfileWelcome: React.FC = (): ReactElement => {
    const currentUser = useCurrentUserContext();
    return (
        <Text
            sx={{
                fontSize: 2,
                color: 'brandPrimary',
                marginTop: 2,
                overflow: 'hidden',
                display: 'inline-block',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                marginLeft: 3,
            }}
        >
            Hi, {currentUser?.firstName}
        </Text>
    );
};

export default ProfileWelcome;
