import {
    useMutation,
    MutationHookOptions,
    MutationTuple,
} from '@apollo/client';

import { CreateOfferData, CreateOfferParams } from './types';
import { CREATE_OFFER } from './gql';

type Options = MutationHookOptions<CreateOfferData, CreateOfferParams>;

const useCreateOffer = (
    options: Options,
): MutationTuple<CreateOfferData, CreateOfferParams> =>
    useMutation(CREATE_OFFER, options);

export default useCreateOffer;
