import {
    useMutation,
    MutationHookOptions,
    MutationTuple,
} from '@apollo/client';

import { UpdateListingData, UpdateListingParams } from './types';
import { UPDATE_LISTING } from './gql';

type Options = MutationHookOptions<UpdateListingData, UpdateListingParams>;

const useUpdateListing = (
    options: Options,
): MutationTuple<UpdateListingData, UpdateListingParams> =>
    useMutation(UPDATE_LISTING, options);

export default useUpdateListing;
