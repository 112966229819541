import { gql } from '@apollo/client';

export const FETCH_CURRENCIES = gql`
    query Currencies {
        currencies @rest(type: "Currencies", path: "/currencies") {
            code
            name
            symbol
            isCrypto
            isStable
            useTag
            isPayable
            image {
                id
                file {
                    url
                }
            }
        }
    }
`;

export const FETCH_PAIRING_CURRENCIES = gql`
    query PairingCurrencies {
        pairingCurrencies
            @rest(type: "PairingCurrencies", path: "/currencies/pairing") {
            code
            name
            symbol
            isCrypto
            isStable
            image {
                id
                file {
                    url
                }
            }
        }
    }
`;

export const FETCH_PRICE_CURRENCIES = gql`
    query PriceCurrencies {
        priceCurrencies @rest(type: "Currencies", path: "/currencies/price") {
            code
            name
            symbol
            isCrypto
            isStable
            image {
                id
                file {
                    url
                }
            }
        }
    }
`;

export const CONVERT_CURRENCY = gql`
    mutation ConvertCurrency($input: JSON!) {
        convertCurrency(input: $input)
            @rest(
                type: "ConvertCurrency"
                path: "/currencies/convert"
                method: "POST"
            ) {
            convertedAmount
        }
    }
`;
