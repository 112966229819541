import { gql } from '@apollo/client';

export const FETCH_ME = gql`
    query Me {
        me @rest(type: "Me", method: "GET", path: "/auth/me") {
            id
            email
            firstName
            lastName
            defaultCurrency
            minListingPrice
            kyc
        }
    }
`;

export const SET_DEFAULT_CURRENCY = gql`
    mutation SetDefaultCurrency($currency: String!) {
        setDefaultCurrency(input: { currency: $currency })
            @rest(
                type: "SetDefaultCurrency"
                path: "/auth/set-default-currency"
                method: "POST"
            ) {
            NoResponse
        }
    }
`;
