import {
    useMutation,
    MutationHookOptions,
    MutationTuple,
} from '@apollo/client';

import { CreateRedirectURLData, CreateRedirectURLParams } from './types';
import { CREATE_REDIRECTION_URL } from './gql';

type Options = MutationHookOptions<
    CreateRedirectURLData,
    CreateRedirectURLParams
>;

const useCreateRedirectURL = (
    options: Options,
): MutationTuple<CreateRedirectURLData, CreateRedirectURLParams> =>
    useMutation(CREATE_REDIRECTION_URL, options);

export default useCreateRedirectURL;
