import React, { ReactElement, Suspense } from 'react';
import { Route, Routes as RRRoutes } from 'react-router-dom';

import { routesDefinitions } from './_Common/Routes/routesDefinitions';
import PageWrapper from './_Common/Components/PageWrapper';
import ToastNotificationContainer from './_Common/Components/ToastNotificationContainer';
import FullScreenLoader from './_Common/Components/FullScreenLoader';
import ProtectedRoutes from './_Common/Routes/ProtectedRoutes';
import PublicRoutes from './_Common/Routes/PublicRoutes';
import useScrollToTop from './_Common/Components/useScrollToTop';

const Home = React.lazy(() => import('./Home'));

const ListingCreate = React.lazy(() => import('./Listing/Create'));
const ListingDetails = React.lazy(() => import('./Listing/Details'));

const DashboardMyProfile = React.lazy(() => import('./Dashboard/MyProfile'));
const DashboardOpenContracts = React.lazy(
    () => import('./Dashboard/OpenContracts'),
);
const DashboardMyOffers = React.lazy(() => import('./Dashboard/MyOffers'));
const DashboardMyListings = React.lazy(() => import('./Dashboard/MyListings'));

const AboutUs = React.lazy(() => import('./AboutUs'));
const HowItWorks = React.lazy(() => import('./HowItWorks'));
const Login = React.lazy(() => import('./Login'));
const Signup = React.lazy(() => import('./Signup'));
const ForgottenPassword = React.lazy(() => import('./ForgottenPassword'));
const ResetPassword = React.lazy(() => import('./ResetPassword'));
const T2Connection = React.lazy(() => import('./T2Connection'));
const Notifications = React.lazy(() => import('./Notifications'));
const Verification = React.lazy(() => import('./Verification'));
const TermsAndConditions = React.lazy(() => import('./TermsAndConditions'));

const PageNotFound = React.lazy(
    () => import('./_Common/Components/PageNotFound'),
);

const Routes: React.FC = (): ReactElement => {
    useScrollToTop();
    return (
        <PageWrapper>
            <ToastNotificationContainer />
            <Suspense fallback={<FullScreenLoader />}>
                <RRRoutes>
                    <Route path={routesDefinitions.HOME} element={<Home />} />
                    <Route
                        path={routesDefinitions.DASHBOARD_MY_PROFILE}
                        element={
                            <ProtectedRoutes>
                                <DashboardMyProfile />
                            </ProtectedRoutes>
                        }
                    />
                    <Route
                        path={routesDefinitions.DASHBOARD_OPEN_CONTRACTS}
                        element={
                            <ProtectedRoutes>
                                <DashboardOpenContracts />
                            </ProtectedRoutes>
                        }
                    />
                    <Route
                        path={routesDefinitions.DASHBOARD_MY_OFFERS}
                        element={
                            <ProtectedRoutes>
                                <DashboardMyOffers />
                            </ProtectedRoutes>
                        }
                    />
                    <Route
                        path={routesDefinitions.DASHBOARD_MY_LISTINGS}
                        element={
                            <ProtectedRoutes>
                                <DashboardMyListings />
                            </ProtectedRoutes>
                        }
                    />

                    <Route path={routesDefinitions.LISTING_CREATE}>
                        <Route
                            path={':id'}
                            element={
                                <ProtectedRoutes>
                                    <ListingCreate />
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path={''}
                            element={
                                <ProtectedRoutes>
                                    <ListingCreate />
                                </ProtectedRoutes>
                            }
                        />
                    </Route>
                    <Route
                        path={routesDefinitions.LISTING_DETAILS}
                        element={<ListingDetails />}
                    />

                    <Route
                        path={routesDefinitions.ABOUT_US}
                        element={<AboutUs />}
                    />
                    <Route
                        path={routesDefinitions.HOW_IT_WORKS}
                        element={<HowItWorks />}
                    />
                    <Route
                        path={routesDefinitions.LOGIN}
                        element={
                            <PublicRoutes>
                                <Login />
                            </PublicRoutes>
                        }
                    />
                    <Route
                        path={routesDefinitions.SIGNUP}
                        element={
                            <PublicRoutes>
                                <Signup />
                            </PublicRoutes>
                        }
                    />
                    <Route
                        path={routesDefinitions.FORGOTTEN_PASSWORD}
                        element={
                            <PublicRoutes>
                                <ForgottenPassword />
                            </PublicRoutes>
                        }
                    />
                    <Route
                        path={routesDefinitions.RESET_PASSWORD}
                        element={
                            <PublicRoutes>
                                <ResetPassword />
                            </PublicRoutes>
                        }
                    />
                    <Route
                        path={routesDefinitions.T2_CONNECTION}
                        element={
                            <ProtectedRoutes>
                                <T2Connection />
                            </ProtectedRoutes>
                        }
                    />
                    <Route
                        path={routesDefinitions.VERIFICATION}
                        element={
                            <PublicRoutes>
                                <Verification />
                            </PublicRoutes>
                        }
                    />

                    <Route
                        path={routesDefinitions.NOTIFICATION}
                        element={<Notifications />}
                    />
                    <Route
                        path={routesDefinitions.TERMS_AND_CONDITIONS}
                        element={<TermsAndConditions />}
                    />
                    <Route path={'*'} element={<PageNotFound />} />
                </RRRoutes>
            </Suspense>
        </PageWrapper>
    );
};

export default Routes;
