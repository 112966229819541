import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';

import { FETCH_LOGIN_URL } from './gql';
import { FetchLoginURLData } from './types';

type Options = QueryHookOptions<FetchLoginURLData>;

type UseFetchBalance = (options: Options) => QueryResult<FetchLoginURLData, {}>;

const useFetchLoginURL: UseFetchBalance = (options: Options) => {
    return useQuery(FETCH_LOGIN_URL, options);
};

export default useFetchLoginURL;
