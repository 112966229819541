import React, { useContext, createContext, useCallback } from 'react';
import { useCookies } from 'react-cookie';
export const TOKEN_COOKIE_NAME = 'authMPToken';

export const useAuthCookie = (cookieName: string) => {
    const [cookies, setCookie, removeCookie] = useCookies([cookieName]);

    const setAuthCookie = (authToken: string | boolean) =>
        setCookie(cookieName, authToken, { path: '/' });

    const removeAuthCookie = () => removeCookie(cookieName, { path: '/' });

    return [cookies[cookieName], setAuthCookie, removeAuthCookie];
};

export const useToken = () => useAuthCookie(TOKEN_COOKIE_NAME);

interface AuthContextProps {
    token?: string;
    isLogged: boolean;
    setToken: (newToken: string | undefined) => void;
}
const AuthContext = createContext<AuthContextProps>({
    isLogged: false,
    setToken: () => {},
});

export const useAuthContext = () => useContext<AuthContextProps>(AuthContext);

interface Props {
    children: React.ReactNode;
}
export const AuthContextProvider: React.FC<Props> = ({ children }) => {
    const [token, setCookie, removeCookie] = useToken();

    const setToken = useCallback(
        (tokenValue: string | undefined) => {
            tokenValue ? setCookie(tokenValue) : removeCookie();
        },
        [setCookie, removeCookie],
    );
    return (
        <AuthContext.Provider
            value={{
                token,
                isLogged: !!token,
                setToken,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
