import React from 'react';
import { LinkProps as RRLinkProps, Link as RRDLink } from 'react-router-dom';

import { Link as LinkTUI, LinkProps } from 'theme-ui';

export const Link = LinkTUI;

export type Props = LinkProps & RRLinkProps;

export const RRLink: React.FC<Props> = (props: Props): JSX.Element => (
    <LinkTUI as={RRDLink} {...props} />
);
