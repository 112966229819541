import React from 'react';

import { View } from '@t2-market-place/view-default';
import appConst from './brandSettings/_appConst';
import brandImages from './brandSettings/Images';
import theme from './brandSettings/theme';

function App() {
    return <View theme={theme} appConst={appConst} brandImages={brandImages} />;
}

export default App;
