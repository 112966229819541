import { useQuery, QueryHookOptions, QueryResult } from '@apollo/client';

import { FETCH_COMMENTS_FOR_OFFER } from './gql';
import {
    FetchCommentsForOfferData,
    FetchCommentsForOfferParams,
} from './types';

type Options = QueryHookOptions<
    FetchCommentsForOfferData,
    FetchCommentsForOfferParams
>;

type UseFetchOfferForListing = (
    options: Options,
) => QueryResult<FetchCommentsForOfferData, FetchCommentsForOfferParams>;

const useFetchCommentsForOffer: UseFetchOfferForListing = (
    options: Options,
) => {
    return useQuery(FETCH_COMMENTS_FOR_OFFER, options);
};

export default useFetchCommentsForOffer;
