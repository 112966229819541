import { gql } from '@apollo/client';

export const LISTING = gql`
    fragment Listing on Listing {
        id
        userId
        status
        type
        price
        currency
        pairingCurrencies
        expirationPeriod
        expirationPeriodType
        media {
            id
            file {
                url
            }
            mimeType
            variants
        }
        coverId
        fee
        feePayer
        validation
        shippingIncluded
        title
        summary
        location
        data {
            year
            make
            model
            vin
            mileage
            mileageUnits
            spareParts
            aesthetic
            mechanical
            turnKey
            shutterCount
            conditions
            extras
            serial
            title
        }
        contractId
        contractName @client
    }
`;

export const CREATE_LISTING = gql`
    mutation CreateListing($input: JSON!) {
        createListing(input: $input)
            @rest(type: "CreateListing", path: "/listings", method: "POST") {
            id
            type
            data
        }
    }
`;

export const UPDATE_LISTING = gql`
    mutation UpdateListing($id: String!, $input: JSON!) {
        updateListing(id: $id, input: $input)
            @rest(
                type: "UpdateListing"
                path: "/listings/{args.id}"
                method: "POST"
            ) {
            ...Listing
        }
    }
    ${LISTING}
`;

export const PUBLISH_LISTING = gql`
    mutation PublishListing($id: String!) {
        publishListing(id: $id, input: {})
            @rest(
                type: "PublishListing"
                path: "/listings/{args.id}/publish"
                method: "POST"
            ) {
            NoResponse
        }
    }
`;

export const UNPUBLISH_LISTING = gql`
    mutation UnPublishListing($id: String!) {
        unPublishListing(id: $id, input: {})
            @rest(
                type: "Listing"
                path: "/listings/{args.id}/unpublish"
                method: "POST"
            ) {
            ...Listing
            numOffers
        }
    }
    ${LISTING}
`;

export const DELETE_LISTING = gql`
    mutation DeleteListing($id: String!) {
        deleteListing(id: $id, input: {})
            @rest(
                type: "DeleteListing"
                path: "/listings/{args.id}"
                method: "DELETE"
            ) {
            NoResponse
        }
    }
`;

export const FETCH_LISTING_BY_ID = gql`
    query ListingById($id: String!) {
        listingById(id: $id)
            @rest(type: "Listing", method: "GET", path: "/listings/{args.id}") {
            ...Listing
        }
    }
    ${LISTING}
`;

export const FETCH_LIVE_LISTINGS = gql`
    query LiveListings {
        liveListings
            @rest(type: "Listing", method: "GET", path: "/listings/live") {
            ...Listing
        }
    }
    ${LISTING}
`;

export const FETCH_LISTING_FEE = gql`
    query FetchListingFee($price: Float!, $currency: String!) {
        fetchListingFee(price: $price, currency: $currency)
            @rest(
                type: "FetchListingFee"
                path: "/listings/fee?price={args.price}&currency={args.currency}"
            ) {
            fee
        }
    }
`;

export const FETCH_MY_LISTINGS = gql`
    query FetchMyListings {
        fetchMyListings
            @rest(type: "Listing", method: "GET", path: "/listings/my") {
            ...Listing
            numOffers
        }
    }
    ${LISTING}
`;

export const ACCEPT_LISTING_OFFER = gql`
    mutation AcceptListingOffer(
        $listingId: String!
        $offerId: String!
        $input: JSON
    ) {
        acceptListingOffer(
            listingId: $listingId
            offerId: $offerId
            input: $input
        )
            @rest(
                type: "AcceptListingOffer"
                path: "/listings/{args.listingId}/offers/{args.offerId}/accept"
                method: "POST"
            ) {
            ...Listing
        }
    }
    ${LISTING}
`;

export const FETCH_RELATED_LISTINGS = gql`
    query RelatedListings($id: String!) {
        relatedListings(id: $id)
            @rest(
                type: "Listing"
                method: "GET"
                path: "/listings/{args.id}/related"
            ) {
            ...Listing
        }
    }
    ${LISTING}
`;

export const FETCH_FILTERED_LIVE_LISTINGS = gql`
    mutation FilteredLiveListings($brandId: String!, $input: JSON!) {
        filteredLiveListings(brandId: $brandId, input: $input)
            @rest(
                type: "Listing"
                path: "/listings/live/filter?brandId={args.brandId}"
                method: "POST"
            ) {
            ...Listing
        }
    }
    ${LISTING}
`;
