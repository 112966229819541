import React, { useState } from 'react';

import { Box, Flex, Text, Heading, LinkButton } from '@t2-market-place/ui';
import { useAuthContext } from '@t2-market-place/state';
import ModalWrapper from '../ModalWrapper';
import useBrandName from '../useBrandName';
import { routesDefinitions } from '../../Routes/routesDefinitions';

interface Props {
    children: React.ReactNode;
}
const LoginDialogWrapper: React.FC<Props> = ({ children }): JSX.Element => {
    const { isLogged } = useAuthContext();
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const brandName = useBrandName();

    return (
        <>
            {isLogged && children}
            {!isLogged && (
                <Flex
                    style={{
                        position: 'relative',
                        flex: 1,
                        width: '100%',
                        flexDirection: 'column',
                    }}
                >
                    {children}
                    <Box
                        onClick={() => setIsDialogOpen(true)}
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            cursor: 'pointer',
                        }}
                    />
                </Flex>
            )}
            {isDialogOpen && (
                <ModalWrapper onRequestClose={() => setIsDialogOpen(false)}>
                    <Flex
                        sx={{
                            flexDirection: 'column',
                            width: ['85vw', '70vw', '60vw', '30vw', '30vw'],
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 4,
                        }}
                    >
                        <Heading
                            variant={'heading.h3'}
                            sx={{
                                color: 'neonBlue',
                                fontSize: 35,
                                textAlign: 'center',
                            }}
                            marginY={2}
                        >
                            Login
                        </Heading>
                        <Text
                            sx={{
                                textAlign: 'center',
                            }}
                        >
                            To use {brandName} please create an account or log
                            in below:
                        </Text>
                        <Flex
                            sx={{
                                width: '100%',
                                marginY: 3,
                                flexDirection: [
                                    'column',
                                    'row',
                                    'row',
                                    'row',
                                    'row',
                                ],
                            }}
                        >
                            <LinkButton
                                sx={{
                                    flex: 1,
                                    marginX: 1,
                                    marginY: [2, 0, 0, 0, 0],
                                }}
                                to={routesDefinitions.LOGIN}
                                onClick={() => setIsDialogOpen(false)}
                            >
                                Log In
                            </LinkButton>
                            <LinkButton
                                sx={{
                                    flex: 1,
                                    marginX: 1,
                                    marginY: [2, 0, 0, 0, 0],
                                }}
                                to={routesDefinitions.SIGNUP}
                                onClick={() => setIsDialogOpen(false)}
                            >
                                Create An Account
                            </LinkButton>
                        </Flex>
                    </Flex>
                </ModalWrapper>
            )}
        </>
    );
};

export default LoginDialogWrapper;
