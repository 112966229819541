import React, { createContext, ReactElement, useContext } from 'react';

import { User, useFetchMe, useAuthContext } from '@t2-market-place/state';
import { Flex, Spinner } from '@t2-market-place/ui';

export interface CurrentUser extends User {}

const CurrentUserContext = createContext<undefined | CurrentUser>(undefined);

export const useCurrentUserContext = () =>
    useContext<undefined | CurrentUser>(CurrentUserContext);

const flexSX = {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
};

interface Props {
    children: React.ReactNode;
}
export const CurrentUserContextProvider: React.FC<Props> = ({
    children,
}): ReactElement => {
    const { isLogged } = useAuthContext();
    const { data, loading } = useFetchMe({
        fetchPolicy: 'cache-and-network',
        skip: !isLogged,
    });

    return (
        <>
            {loading && (
                <Flex sx={flexSX}>
                    <Spinner />
                </Flex>
            )}

            {!loading && (
                <CurrentUserContext.Provider value={data?.me}>
                    {children}
                </CurrentUserContext.Provider>
            )}
        </>
    );
};
