import { useQuery, QueryHookOptions } from '@apollo/client';

import { FetchShippingAddressDataType } from './types';
import { FETCH_SHIPPING_ADDRESS } from './gql';

type Options = QueryHookOptions<FetchShippingAddressDataType, {}>;

const useFetchShippingAddress = (options: Options) =>
    useQuery(FETCH_SHIPPING_ADDRESS, options);

export default useFetchShippingAddress;
