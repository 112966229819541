import { useQuery, QueryHookOptions, QueryResult } from '@apollo/client';

import { FetchPriceCurrencies } from './types';
import { FETCH_PRICE_CURRENCIES } from './gql';

type Options = QueryHookOptions<FetchPriceCurrencies>;

const useFetchPriceCurrencies = (
    options: Options,
): QueryResult<FetchPriceCurrencies> => {
    return useQuery(FETCH_PRICE_CURRENCIES, options);
};

export default useFetchPriceCurrencies;
