import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const ToastNotificationContainer: React.FC = (): JSX.Element => (
    <ToastContainer
        position="top-center"
        hideProgressBar={true}
        theme="colored"
    />
);

export default ToastNotificationContainer;
