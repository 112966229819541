import { useQuery, QueryHookOptions, QueryResult } from '@apollo/client';

import { FETCH_RELATED_LISTINGS } from './gql';
import { FetchRelatedListingsData, FetchRelatedListingsParams } from './types';

type Options = QueryHookOptions<
    FetchRelatedListingsData,
    FetchRelatedListingsParams
>;

type UseFetchRelatedListings = (
    options: Options,
) => QueryResult<FetchRelatedListingsData, FetchRelatedListingsParams>;

const useFetchRelatedListings: UseFetchRelatedListings = (options: Options) => {
    return useQuery(FETCH_RELATED_LISTINGS, options);
};

export default useFetchRelatedListings;
