import React from 'react';
import { Flex, Modal } from '@t2-market-place/ui';

interface Props {
    onRequestClose?: () => void;
    children: JSX.Element | JSX.Element[];
    isOpen?: boolean;
}
const ModalWrapper: React.FC<Props> = ({
    children,
    onRequestClose,
    isOpen,
}: Props): JSX.Element => {
    return (
        <Modal
            isOpen={isOpen !== undefined ? isOpen : true}
            onRequestClose={onRequestClose}
            style={{
                overlay: {
                    backgroundColor: `#02304780`,
                    zIndex: 10001,
                },
                content: {
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'transparent',
                    border: 'none',
                    zIndex: 10001,
                    opacity: 1,
                    overflow: 'auto',

                    maxHeight: '90vh',
                },
            }}
        >
            <Flex
                sx={{
                    backgroundColor: 'white',
                    borderColor: 'gemSilica',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderRadius: [20, 30, 30, 30],
                }}
            >
                <Flex
                    sx={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        position: 'relative',
                    }}
                >
                    {children}
                </Flex>
            </Flex>
        </Modal>
    );
};

export default ModalWrapper;
