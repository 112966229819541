import React, { useEffect } from 'react';
import {
    NavigationType,
    useLocation,
    useNavigationType,
} from 'react-router-dom';

const useBackButton = () => {
    const navType = useNavigationType();
    return navType === NavigationType.Pop;
};

const useScrollToTop = () => {
    const { pathname } = useLocation();

    const isPop = useBackButton();

    const scrollToTop = () => window.scrollTo(0, 0);

    useEffect(() => {
        scrollToTop();
    }, [pathname, isPop]);

    useEffect(() => {
        if (window?.history?.scrollRestoration) {
            window.history.scrollRestoration = 'manual';
        }

        if (history?.scrollRestoration) {
            history.scrollRestoration = 'manual';
        }

        window.addEventListener('beforeunload', scrollToTop);
        return () => {
            window.removeEventListener('beforeunload', scrollToTop);
        };
    }, []);
};

export default useScrollToTop;
