import React from 'react';
import { LinkButton } from '@t2-market-place/ui';
import { navButtonSX } from '../_commonSX';
import { routesDefinitions } from '../../../../../Routes/routesDefinitions';

const RegisterButton: React.FC = (): JSX.Element => {
    return (
        <LinkButton
            marginLeft={2}
            sx={navButtonSX}
            to={routesDefinitions.SIGNUP}
        >
            Create Account
        </LinkButton>
    );
};

export default RegisterButton;
