import { useQuery, QueryHookOptions, QueryResult } from '@apollo/client';

import { FetchCurrencies } from './types';
import { FETCH_CURRENCIES } from './gql';

type Options = QueryHookOptions<FetchCurrencies>;

const useFetchCurrencies = (options: Options): QueryResult<FetchCurrencies> => {
    return useQuery(FETCH_CURRENCIES, options);
};

export default useFetchCurrencies;
