import {
    useMutation,
    MutationHookOptions,
    MutationTuple,
} from '@apollo/client';

import { SetShippingAddressDataType, SetShippingAddressParams } from './types';
import { SET_SHIPPING_ADDRESS, FETCH_SHIPPING_ADDRESS } from './gql';

type Options = MutationHookOptions<
    SetShippingAddressDataType,
    SetShippingAddressParams
>;

const useSetShippingAddress = (
    options: Options,
): MutationTuple<SetShippingAddressDataType, SetShippingAddressParams> =>
    useMutation(SET_SHIPPING_ADDRESS, {
        ...options,
        ...{
            refetchQueries: [FETCH_SHIPPING_ADDRESS],
            awaitRefetchQueries: true,
        },
    });

export default useSetShippingAddress;
