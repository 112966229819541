import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '@t2-market-place/state';
import { routesDefinitions } from './routesDefinitions';

interface Props {
    children: React.ReactNode;
}
const PublicRoutesWrapper: React.FC<Props> = ({ children }): JSX.Element => {
    const { isLogged } = useAuthContext();

    return isLogged ? (
        <Navigate to={routesDefinitions.HOME} />
    ) : (
        <>{children}</>
    );
};

export default PublicRoutesWrapper;
