import React, { useRef } from 'react';

import { useAuthContext } from '@t2-market-place/state';
import {
    Box,
    Flex,
    IconFiMenu,
    RRNavLink,
    Text,
    NavLink,
    IconMdLogout,
    ThemeUIStyleObject,
} from '@t2-market-place/ui';

import { routesDefinitions } from '../../../../Routes/routesDefinitions';
import LoginDialogWrapper from '../../../Login/LoginDialogWrapper';
import { useAppConstContext } from '../../../useAppConst';

import { dashboardNavDefinition } from '../DashboardNavDefinition';
import Logo from '../../Logo';
import useToggleMenu from '../useToggleMenu';
import useLogout from '../useLogout';
import AuthLink from './AuthLink';

const navSx = {
    borderTopWidth: 1,
    borderTopColor: 'brandPrimary',
    borderTopStyle: 'solid',
    width: '100%',
    textAlign: 'center',
    textTransform: 'none',
    padding: 3,
} as ThemeUIStyleObject;

const dashboardNavSX = {
    textTransform: 'none',
    position: 'relative',
    borderTopWidth: 1,
    borderTopColor: 'neonBlue',
    borderTopStyle: 'solid',
    width: '100%',
    padding: 3,
    fontSize: 18,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&>svg': {
        size: 25,
        position: 'absolute',
        right: 20,
    },
} as ThemeUIStyleObject;

const MobileNav: React.FC = (): JSX.Element => {
    const { PAGE_PADDING_TOP } = useAppConstContext();
    const logout = useLogout();
    const { isLogged } = useAuthContext();
    const menuRef = useRef(null);
    const buttonMenuRef = useRef(null);

    const { isMenuOpen, toggleMenu, closeMenu } = useToggleMenu({
        menuRef,
        buttonMenuRef,
    });

    return (
        <>
            <Box
                sx={{
                    display: ['flex', 'flex', 'flex', 'flex', 'none'],
                    backgroundColor: 'bgHeader',
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    height: 100,
                }}
            >
                <Flex
                    sx={{
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingX: [3, 3, 5, 5, 5],
                        '& svg': {
                            color: 'hamburgerMenuIcon',
                            marginLeft: 2,
                        },
                    }}
                >
                    <Logo />
                    <Flex>
                        <AuthLink />
                        <div ref={buttonMenuRef} onClick={toggleMenu}>
                            <IconFiMenu size={40} />
                        </div>
                    </Flex>
                </Flex>
            </Box>
            {isMenuOpen && (
                <Flex
                    ref={menuRef}
                    sx={{
                        position: 'absolute',
                        top: PAGE_PADDING_TOP,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'bgHeaderMenu',
                        zIndex: 1000,
                        flexDirection: 'column',
                    }}
                >
                    <Flex
                        sx={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingX: 3,
                            backgroundColor: 'bgHeaderMenu',
                        }}
                    >
                        <LoginDialogWrapper>
                            <RRNavLink
                                sx={navSx}
                                to={routesDefinitions.LISTING_CREATE}
                                onClick={closeMenu}
                            >
                                Sell my item
                            </RRNavLink>
                        </LoginDialogWrapper>
                        <RRNavLink
                            sx={navSx}
                            to={routesDefinitions.HOW_IT_WORKS}
                            onClick={closeMenu}
                        >
                            How It Works
                        </RRNavLink>
                        <RRNavLink
                            sx={navSx}
                            to={routesDefinitions.ABOUT_US}
                            onClick={closeMenu}
                        >
                            About
                        </RRNavLink>
                        {!isLogged && (
                            <>
                                <RRNavLink
                                    sx={navSx}
                                    to={routesDefinitions.LOGIN}
                                    onClick={closeMenu}
                                >
                                    Create Account
                                </RRNavLink>
                            </>
                        )}
                        {isLogged &&
                            dashboardNavDefinition.map(
                                ({ to, label, Icon }) => {
                                    return (
                                        <RRNavLink
                                            key={to}
                                            to={to}
                                            sx={dashboardNavSX}
                                            onClick={closeMenu}
                                        >
                                            <Text>{label}</Text>
                                            <Icon />
                                        </RRNavLink>
                                    );
                                },
                            )}
                        {isLogged && (
                            <NavLink
                                sx={dashboardNavSX}
                                onClick={() => {
                                    logout();
                                    closeMenu();
                                }}
                            >
                                <Text>Logout</Text>
                                <IconMdLogout />
                            </NavLink>
                        )}
                    </Flex>
                </Flex>
            )}
        </>
    );
};

export default MobileNav;
