import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '@t2-market-place/state';
import { routesDefinitions } from './routesDefinitions';

interface Props {
    children: React.ReactNode;
}
const ProtectedRoutesWrapper: React.FC<Props> = ({ children }): JSX.Element => {
    const { isLogged } = useAuthContext();
    if (!isLogged) {
        return <Navigate to={routesDefinitions.HOME} />;
    } else {
        return <>{children}</>;
    }
};

export default ProtectedRoutesWrapper;
