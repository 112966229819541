import { gql } from '@apollo/client';

export const FETCH_CONNECTED_ACCOUNTS = gql`
    query ConnectedAccounts {
        connectedAccounts
            @rest(
                type: "ConnectedAccounts"
                method: "GET"
                path: "/auth/connected-accounts"
            ) {
            type
            data {
                login
                name
                kyc
            }
        }
    }
`;

export const DISCONNCET_ACCOUNT = gql`
    mutation DisconnectAccount($type: String!) {
        disconnectAccount(type: $type, input: {})
            @rest(
                type: "DisconnectAccount"
                path: "/auth/connected-accounts/{args.type}"
                method: "DELETE"
            ) {
            NoResponse
        }
    }
`;
