import React, { useContext, createContext } from 'react';

export interface AppConstContextProps {
    HEADER_HEIGHT: [number, number, number, number, number];
    PAGE_PADDING_TOP: [number, number, number, number, number];
    FOOTER_HEIGHT: [number, number, number, number, number];
    PAGE_PADDING_BOTTOM: [number, number, number, number, number];
    MAX_WIDTH_DESKTOP_PAGE: number;
}
const AppConstContext = createContext<AppConstContextProps>({
    HEADER_HEIGHT: [100, 100, 100, 100, 150],
    PAGE_PADDING_TOP: [100, 100, 100, 100, 150],
    FOOTER_HEIGHT: [150, 150, 150, 150, 150],
    PAGE_PADDING_BOTTOM: [150, 150, 150, 150, 150],
    MAX_WIDTH_DESKTOP_PAGE: 1400,
});

export const useAppConstContext = () =>
    useContext<AppConstContextProps>(AppConstContext);

interface Props {
    children: React.ReactNode;
    appConst: AppConstContextProps;
}
export const AppConstContextProvider: React.FC<Props> = ({
    children,
    appConst,
}) => {
    return (
        <AppConstContext.Provider value={appConst}>
            {children}
        </AppConstContext.Provider>
    );
};
