import { gql } from '@apollo/client';

export const VERIFY = gql`
    mutation Verify($verificationId: String!) {
        verify(input: { code: $verificationId })
            @rest(type: "Verify", path: "/auth/verify", method: "POST") {
            NoResponse
        }
    }
`;
