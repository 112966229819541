import React from 'react';
import { LinkProps as RRLinkProps, Link as RRDLink } from 'react-router-dom';
import { Button, ButtonProps as ButtonPropsTUI } from 'theme-ui';

export type Props = ButtonPropsTUI & RRLinkProps;

const LinkButton: React.FC<Props> = (props: Props) => (
    <Button as={RRDLink} {...props} />
);
export default LinkButton;
