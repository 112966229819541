import {
    ListingCameraDataType,
    ListingComputerDataType,
    ListingJewelryDataType,
    ListingOtherDataType,
    ListingTypeProperty,
    ListingVehiclesDataType,
    ListingWatchDataType,
} from './types';
interface Props {
    type?: ListingTypeProperty;
    data:
        | ListingVehiclesDataType
        | ListingCameraDataType
        | ListingComputerDataType
        | ListingWatchDataType
        | ListingJewelryDataType
        | ListingOtherDataType;
}
export type GetContractName = (props: Props) => string;
export const getContractName: GetContractName = ({ type, data }) => {
    switch (type) {
        case ListingTypeProperty.CAR:
            const vehicles = data as ListingVehiclesDataType;
            return `${vehicles.year || ''} ${vehicles.make || ''} ${
                vehicles.model || ''
            }`;
        case ListingTypeProperty.CAMERA:
            const camera = data as ListingVehiclesDataType;
            return `${camera.make || ''} ${camera.model || ''}`;
        case ListingTypeProperty.COMPUTER:
            const computer = data as ListingComputerDataType;
            return `${computer.make || ''} ${computer.model || ''}`;
        case ListingTypeProperty.WATCH:
            const watch = data as ListingWatchDataType;
            return `${watch.make || ''} ${watch.model || ''}`;
        case ListingTypeProperty.JEWELRY:
            const jewelry = data as ListingJewelryDataType;
            return `${jewelry.title || ''}`;
        case ListingTypeProperty.OTHER:
            const other = data as ListingOtherDataType;
            return `${other.title || ''}`;
        default:
            return '';
    }
};
