import {
    useLazyQuery,
    useQuery,
    QueryResult,
    QueryHookOptions,
    QueryTuple,
} from '@apollo/client';
import { FETCH_LISTING_BY_ID } from './gql';
import { FetchListingByIdData, FetchListingByIdParams } from './types';

type Options = QueryHookOptions<FetchListingByIdData, FetchListingByIdParams>;

type UseFetchListingByIdLazy = (
    options: Options,
) => QueryTuple<FetchListingByIdData, FetchListingByIdParams>;

type UseFetchListingById = (
    options: Options,
) => QueryResult<FetchListingByIdData, FetchListingByIdParams>;

export const useFetchListingByIdLazy: UseFetchListingByIdLazy = (
    options: Options,
) => {
    return useLazyQuery(FETCH_LISTING_BY_ID, options);
};

export const useFetchListingById: UseFetchListingById = (options: Options) => {
    return useQuery(FETCH_LISTING_BY_ID, options);
};
