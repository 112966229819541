import {
    useMutation,
    MutationHookOptions,
    MutationTuple,
} from '@apollo/client';

import { AcceptListingOfferData, AcceptListingOfferParams } from './types';
import { ACCEPT_LISTING_OFFER } from './gql';

type Options = MutationHookOptions<
    AcceptListingOfferData,
    AcceptListingOfferParams
>;

const useAcceptListingOffer = (
    options: Options,
): MutationTuple<AcceptListingOfferData, AcceptListingOfferParams> =>
    useMutation(ACCEPT_LISTING_OFFER, options);

export default useAcceptListingOffer;
