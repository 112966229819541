import { gql } from '@apollo/client';

export const RESEND_VERIFICATION = gql`
    mutation ResendVerification {
        resendVerification(input: {})
            @rest(
                type: "ResendVerification"
                path: "/auth/verification"
                method: "POST"
            ) {
            NoResponse
        }
    }
`;
