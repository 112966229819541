import { useQuery, QueryHookOptions, QueryResult } from '@apollo/client';

import { FETCH_OFFERS_FOR_LISTING } from './gql';
import {
    FetchOffersForListingData,
    FetchOffersForListingParams,
} from './types';

type Options = QueryHookOptions<
    FetchOffersForListingData,
    FetchOffersForListingParams
>;

type UseFetchOfferForListing = (
    options: Options,
) => QueryResult<FetchOffersForListingData, FetchOffersForListingParams>;

const useFetchOfferForListing: UseFetchOfferForListing = (options: Options) => {
    return useQuery(FETCH_OFFERS_FOR_LISTING, options);
};

export default useFetchOfferForListing;
