import {
    useMutation,
    MutationHookOptions,
    MutationTuple,
} from '@apollo/client';

import { VerifyData, VerifyParams } from './types';
import { VERIFY } from './gql';

type Options = MutationHookOptions<VerifyData, VerifyParams>;

const useVerify = (options: Options): MutationTuple<VerifyData, VerifyParams> =>
    useMutation(VERIFY, options);

export default useVerify;
