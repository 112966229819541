import { ThemeUICSSObject } from 'theme-ui';

export const navButtonSX = {
    paddingY: 1,
    paddingX: 4,
    minWidth: 160,
    fontSize: 4,
    borderRadius: 7,
    textTransform: 'none',
} as ThemeUICSSObject;
