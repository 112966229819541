import React from 'react';
import { useAuthContext } from '@t2-market-place/state';
import { RRNavLink } from '@t2-market-place/ui';
import { routesDefinitions } from '../../../../Routes/routesDefinitions';
import ProfileWelcome from './ProfileWelcome';

const AuthLink: React.FC = (): JSX.Element => {
    const { isLogged } = useAuthContext();
    return (
        <>
            {!isLogged && (
                <RRNavLink variant={'headNav'} to={routesDefinitions.LOGIN}>
                    Login
                </RRNavLink>
            )}
            {isLogged && <ProfileWelcome />}
        </>
    );
};

export default AuthLink;
