import {
    useMutation,
    MutationHookOptions,
    MutationTuple,
} from '@apollo/client';

import {
    FetchFilteredLiveListingsData,
    FetchFilteredLiveListingsParams,
} from './types';
import { FETCH_FILTERED_LIVE_LISTINGS } from './gql';

type Options = MutationHookOptions<
    FetchFilteredLiveListingsData,
    FetchFilteredLiveListingsParams
>;

const useFetchFilteredLiveListings = (
    options: Options,
): MutationTuple<
    FetchFilteredLiveListingsData,
    FetchFilteredLiveListingsParams
> => useMutation(FETCH_FILTERED_LIVE_LISTINGS, options);

export default useFetchFilteredLiveListings;
