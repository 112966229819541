import React from 'react';
import { LinkButton } from '@t2-market-place/ui';
import { navButtonSX } from '../_commonSX';
import { routesDefinitions } from '../../../../../Routes/routesDefinitions';

const LoginButton: React.FC = (): JSX.Element => {
    return (
        <LinkButton sx={navButtonSX} to={routesDefinitions.LOGIN}>
            Login
        </LinkButton>
    );
};

export default LoginButton;
