import { gql } from '@apollo/client';

export const SMART_CONTRACT = gql`
    fragment SmartContract on SmartContract {
        id
        type
        status
        seller
        buyer
        images {
            id
            file {
                url
            }
            mimeType
        }
        videos {
            id
            file {
                url
            }
            mimeType
        }
        coverId
        data {
            year
            make
            model
            vin
            mileage
            mileageUnits
            spareParts
            aesthetic
            mechanical
            turnKey
            shutterCount
            conditions
            extras
            serial
            title
        }
        isReviewer
        listingId
        contractName @client
    }
`;

export const FETCH_OPEN_CONTRACTS = gql`
    query OpenContracts {
        openContracts
            @rest(type: "SmartContract", method: "GET", path: "/t2/sc/open") {
            ...SmartContract
        }
    }
    ${SMART_CONTRACT}
`;

export const FETCH_BALANCE = gql`
    query Balance {
        balance
            @rest(type: "Balance", method: "GET", path: "/t2/wallet/balances") {
            currency
            balance
            baseBalance
        }
    }
`;

export const CREATE_REDIRECTION_URL = gql`
    mutation CreateRedirectionURL($input: JSON!) {
        createRedirectionURL(input: $input)
            @rest(
                type: "CreateRedirectionURL"
                path: "/t2/redirection-url"
                method: "POST"
            ) {
            url
        }
    }
`;

export const FETCH_LOGIN_URL = gql`
    query LoginURL {
        loginURL @rest(type: "LoginURL", method: "GET", path: "/t2/login-url") {
            url
        }
    }
`;
