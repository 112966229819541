import React from 'react';
import { NavLink as NavLinkUI, NavLinkProps } from 'theme-ui';

import { Link, LinkProps } from 'react-router-dom';

type RRNavLinkProps = LinkProps & NavLinkProps;

export const NavLink = NavLinkUI;

export const RRNavLink: React.FC<RRNavLinkProps> = (props: RRNavLinkProps) => (
    <NavLinkUI as={Link} {...props} />
);
