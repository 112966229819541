import { useQuery, QueryHookOptions, QueryResult } from '@apollo/client';

import { FETCH_BALANCE } from './gql';
import { FetchBalanceData } from './types';

type Options = QueryHookOptions<FetchBalanceData>;

type UseFetchBalance = (options: Options) => QueryResult<FetchBalanceData>;

const useFetchBalance: UseFetchBalance = (options: Options) => {
    return useQuery(FETCH_BALANCE, options);
};

export default useFetchBalance;
