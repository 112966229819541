import React, { useMemo } from 'react';
import {
    ApolloClient,
    ApolloProvider,
    InMemoryCache,
    ApolloLink,
} from '@apollo/client';
import { RestLink } from 'apollo-link-rest';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getContractName, Listing } from './Listing';
import { useAuthContext } from './Auth/useAuth';

interface Props {
    children: React.ReactElement;
}

const createClient = (token: string, logout: () => void) => {
    const restLink = new RestLink({
        uri: '/api',
        bodySerializers: {
            mediaEncode: (data: File[], headers: Headers) => {
                const formData = new FormData();
                data.forEach((item) => {
                    formData.append('files', item);
                });
                headers.set('Accept', '*/*');
                return { body: formData, headers };
            },
            formData: (data, headers: Headers) => {
                const formData = new FormData();
                Object.keys(data).forEach((key) => {
                    formData.append(key, data[key]);
                });
                headers.set('Accept', '*/*');
                return { body: formData, headers };
            },
        },
    });
    const cache = new InMemoryCache({
        typePolicies: {
            Listing: {
                fields: {
                    contractName: {
                        read(_, { readField }) {
                            const type = readField('type') as Listing['type'];
                            const data = readField('data') as Listing['data'];
                            return getContractName({ type, data });
                        },
                    },
                },
            },
            SmartContract: {
                fields: {
                    contractName: {
                        read(_, { readField }) {
                            const type = readField('type') as Listing['type'];
                            const data = readField('data') as Listing['data'];
                            return getContractName({ type, data });
                        },
                    },
                },
            },
        },
    });

    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                ...(token
                    ? {
                          authorization: `Bearer ${token}`,
                      }
                    : {}),
            },
        };
    });

    const logoutLink = onError(({ networkError = {} as any }) => {
        const status: number =
            networkError && networkError.statusCode
                ? networkError.statusCode
                : null;
        if (status === 401 && token) {
            client.clearStore().then(logout);
        }
    });

    const client = new ApolloClient({
        link: ApolloLink.from([authLink, logoutLink, restLink]),
        cache,
    });
    return client;
};

const Provider: React.FC<Props> = ({ children }: Props) => {
    const { token, setToken } = useAuthContext();
    const client = useMemo(() => {
        const logout = () => {
            alert('Login session expired.Please, login again');
            setToken(undefined);
        };
        return createClient(token || '', logout);
    }, [token, setToken]);
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Provider;
