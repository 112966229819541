import React from 'react';
import { useAuthContext } from '@t2-market-place/state';
import ProfileButton from './ProfileButton';
import LoginButton from './LoginButton';
import RegisterButton from './RegisterButton';

const AuthButtons: React.FC = (): JSX.Element => {
    const { isLogged } = useAuthContext();
    return (
        <>
            {isLogged && <ProfileButton />}
            {!isLogged && (
                <>
                    <LoginButton />
                    <RegisterButton />
                </>
            )}
        </>
    );
};

export default AuthButtons;
