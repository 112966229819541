import { useMutation } from '@apollo/client';

import {
    EDIT_COMMENT,
    FETCH_COMMENTS_FOR_OFFER,
    FETCH_COMMENTS_FOR_LISTING,
} from './gql';
import { EditOfferCommentData, EditOfferCommentParams } from './types';

export const useEditOfferComment = ({ offerId }: { offerId: string }) =>
    useMutation<EditOfferCommentData, EditOfferCommentParams>(EDIT_COMMENT, {
        refetchQueries: [
            {
                query: FETCH_COMMENTS_FOR_OFFER,
                variables: {
                    id: offerId,
                },
            },
        ],
        awaitRefetchQueries: true,
    });

export const useEditListingComment = ({ listingId }: { listingId: string }) =>
    useMutation<EditOfferCommentData, EditOfferCommentParams>(EDIT_COMMENT, {
        refetchQueries: [
            {
                query: FETCH_COMMENTS_FOR_LISTING,
                variables: {
                    id: listingId,
                },
            },
        ],
        awaitRefetchQueries: true,
    });
