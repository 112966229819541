import {
    useMutation,
    MutationHookOptions,
    MutationTuple,
} from '@apollo/client';

import { ConvertCurrencyData, ConvertCurrencyParams } from './types';
import { CONVERT_CURRENCY } from './gql';

type Options = MutationHookOptions<ConvertCurrencyData, ConvertCurrencyParams>;

const useConvertCurrency = (
    options: Options,
): MutationTuple<ConvertCurrencyData, ConvertCurrencyParams> =>
    useMutation(CONVERT_CURRENCY, options);

export default useConvertCurrency;
