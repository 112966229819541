import {
    useMutation,
    MutationHookOptions,
    MutationTuple,
} from '@apollo/client';

import { PublishListingData, PublishListingParams } from './types';
import { PUBLISH_LISTING } from './gql';

type Options = MutationHookOptions<PublishListingData, PublishListingParams>;

const usePublishListing = (
    options: Options,
): MutationTuple<PublishListingData, PublishListingParams> =>
    useMutation(PUBLISH_LISTING, options);

export default usePublishListing;
