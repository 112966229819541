import { useMutation } from '@apollo/client';

import {
    DELETE_COMMENT,
    FETCH_COMMENTS_FOR_OFFER,
    FETCH_COMMENTS_FOR_LISTING,
} from './gql';
import { DeleteOfferCommentData, DeleteOfferCommentParams } from './types';

export const useDeleteOfferComment = ({ offerId }: { offerId: string }) => {
    return useMutation<DeleteOfferCommentData, DeleteOfferCommentParams>(
        DELETE_COMMENT,
        {
            refetchQueries: [
                {
                    query: FETCH_COMMENTS_FOR_OFFER,
                    variables: {
                        id: offerId,
                    },
                },
            ],
            awaitRefetchQueries: true,
        },
    );
};

export const useDeleteListingComment = ({
    listingId,
}: {
    listingId: string;
}) => {
    return useMutation<DeleteOfferCommentData, DeleteOfferCommentParams>(
        DELETE_COMMENT,
        {
            refetchQueries: [
                {
                    query: FETCH_COMMENTS_FOR_LISTING,
                    variables: {
                        id: listingId,
                    },
                },
            ],
            awaitRefetchQueries: true,
        },
    );
};
