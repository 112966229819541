import { gql } from '@apollo/client';
export const LOGIN = gql`
    mutation Login($login: String!, $pass: String!, $brandId: String!) {
        login(input: { login: $login, pass: $pass, brandId: $brandId })
            @rest(type: "login", path: "/auth/token", method: "POST") {
            token
            verified
        }
    }
`;

export const REFRESH_TOKEN = gql`
    mutation RefreshToken {
        refreshToken(input: {})
            @rest(
                type: "RefreshToken"
                path: "/auth/refresh-token"
                method: "POST"
            ) {
            token
            verified
        }
    }
`;
