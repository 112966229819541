import {
    useMutation,
    MutationHookOptions,
    MutationTuple,
} from '@apollo/client';

import { ResetPasswordData, ResetPasswordParams } from './types';
import { RESET_PASSWORD } from './gql';

type Options = MutationHookOptions<ResetPasswordData, ResetPasswordParams>;

const useResetPassword = (
    options: Options,
): MutationTuple<ResetPasswordData, ResetPasswordParams> =>
    useMutation(RESET_PASSWORD, options);

export default useResetPassword;
