import { useMutation, MutationHookOptions } from '@apollo/client';

import { ResendVerificationData } from './types';
import { RESEND_VERIFICATION } from './gql';

type Options = MutationHookOptions<ResendVerificationData>;

const useResendVerification = (options: Options) => {
    return useMutation(RESEND_VERIFICATION, options);
};

export default useResendVerification;
