import { ViewProps } from '@t2-market-place/view-default';

const appConst: ViewProps['appConst'] = {
    HEADER_HEIGHT: [100, 100, 100, 100, 150],
    PAGE_PADDING_TOP: [100, 100, 100, 100, 150],
    FOOTER_HEIGHT: [250, 200, 200, 200, 150],
    PAGE_PADDING_BOTTOM: [250, 200, 200, 200, 150],
    MAX_WIDTH_DESKTOP_PAGE: 1400,
};
export default appConst;
