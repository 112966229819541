import { gql } from '@apollo/client';

export const PASSWORD_CHANGE = gql`
    mutation PasswordChange($oldPass: String!, $newPass: String!) {
        passwordChange(input: { oldPass: $oldPass, newPass: $newPass })
            @rest(
                type: "passwordChange"
                path: "/auth/change-pass"
                method: "POST"
            ) {
            NoResponse
        }
    }
`;
