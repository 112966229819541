import React, { createContext, useContext } from 'react';
import useFetchConnectedAccounts from './useFetchConnectedAccounts';
import { useAuthContext } from '../useAuth';
import { ConnectedAccountsDataType } from './types';
import { NetworkStatus } from '@apollo/client';

export { NetworkStatus } from '@apollo/client';

interface ConnectedAccountsContextProps {
    isConnectedToT2: boolean;
    isLoadingT2Connection: boolean;
    errorT2Connection?: string;
    connectedData?: ConnectedAccountsDataType;
    isKYCVerified: boolean;
    refetch: () => void;
    isRefetching: boolean;
}
const ConnectedAccountsContext = createContext<ConnectedAccountsContextProps>({
    isConnectedToT2: false,
    isLoadingT2Connection: false,
    isKYCVerified: false,
    refetch: () => {},
    isRefetching: false,
});

export const useConnectedAccountsContext = () =>
    useContext<ConnectedAccountsContextProps>(ConnectedAccountsContext);

interface Props {
    children: React.ReactNode;
}
export const ConnectedAccountsContextProvider: React.FC<Props> = ({
    children,
}) => {
    const { isLogged } = useAuthContext();
    const { data, error, refetch, networkStatus } = useFetchConnectedAccounts({
        skip: !isLogged,
        notifyOnNetworkStatusChange: true,
    });
    const isConnectedToT2 = !!data?.connectedAccounts.some(
        ({ type }) => type === 't2',
    );
    const isKYCVerified = !!data?.connectedAccounts.some(
        ({ type, data: { kyc } }) => type === 't2' && kyc,
    );

    return (
        <ConnectedAccountsContext.Provider
            value={{
                isConnectedToT2,
                connectedData: data,
                isLoadingT2Connection: networkStatus === NetworkStatus.loading,
                errorT2Connection: error?.message,
                isKYCVerified,
                refetch,
                isRefetching: networkStatus === NetworkStatus.refetch,
            }}
        >
            {children}
        </ConnectedAccountsContext.Provider>
    );
};
