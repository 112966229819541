import React from 'react';

import { routesDefinitions } from '../../Routes/routesDefinitions';
import { Image, RRLink } from '@t2-market-place/ui';
import { useBrandImagesContext } from '../useBrandImages';

const Logo: React.FC = (): JSX.Element => {
    const { imgT2LogoLong } = useBrandImagesContext();
    return (
        <RRLink reloadDocument={true} to={routesDefinitions.HOME}>
            <Image
                sx={{ maxHeight: [70, 96, 96, 96, 96] }}
                src={imgT2LogoLong}
            />
        </RRLink>
    );
};

export default Logo;
