import { useQuery, QueryHookOptions, QueryResult } from '@apollo/client';

import { FETCH_COMMENTS_FOR_LISTING } from './gql';
import {
    FetchCommentsForListingData,
    FetchCommentsForOfferListingParams,
} from './types';

type Options = QueryHookOptions<
    FetchCommentsForListingData,
    FetchCommentsForOfferListingParams
>;

type UseFetchOfferForListing = (
    options: Options,
) => QueryResult<
    FetchCommentsForListingData,
    FetchCommentsForOfferListingParams
>;

const useFetchCommentsForListing: UseFetchOfferForListing = (
    options: Options,
) => {
    return useQuery(FETCH_COMMENTS_FOR_LISTING, options);
};

export default useFetchCommentsForListing;
