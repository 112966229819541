import React from 'react';
import { Flex, Spinner, Text } from '@t2-market-place/ui';

interface Props {
    message?: string;
}
const FullScreenLoader: React.FC<Props> = ({
    message = 'Please Wait...',
}): JSX.Element => (
    <Flex
        sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgb(255,255,255,0.9)',
            zIndex: 100,
        }}
    >
        <Spinner />
        <Text>{message}</Text>
    </Flex>
);

export default FullScreenLoader;
