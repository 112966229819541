import { gql } from '@apollo/client';

export const CREATE_OFFER = gql`
    mutation CreateOffer($id: String!, $input: JSON!) {
        createOffer(id: $id, input: $input)
            @rest(
                type: "CreateOffer"
                path: "/listings/{args.id}/offers"
                method: "POST"
            ) {
            id
            status
            listing
            price
            currency
            description
            address
            expiration
        }
    }
`;

export const FETCH_OFFERS_FOR_LISTING = gql`
    query OffersForListing($id: String!) {
        offersForListing(id: $id)
            @rest(
                type: "OffersForListing"
                method: "GET"
                path: "/listings/{args.id}/offers"
            ) {
            id
            status
            price
            currency
            description
            address
            shippingData
            expiration
        }
    }
`;

export const FETCH_MY_OFFERS = gql`
    query FetchMyOffers {
        fetchMyOffers
            @rest(type: "FetchMyOffers", method: "GET", path: "/offers/my") {
            id
            status
            listing
            price
            currency
            description
            address
            shippingData
            expiration
        }
    }
`;
