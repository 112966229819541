import { gql } from '@apollo/client';

export const UPLOAD_FILES_MUTATION = gql`
    mutation ($files: [Upload!]!) {
        uploadFiles(input: $files)
            @rest(
                type: "UploadFiles"
                path: "/files"
                method: "POST"
                bodySerializer: "mediaEncode"
            ) {
            id
            file {
                url
                width
                height
            }
            originalFileName
            mimeType
            uploaded
            variants
        }
    }
`;

export const CROP_IMAGE = gql`
    mutation CropImage($id: String!, $input: JSON!) {
        cropImage(id: $id, input: $input)
            @rest(
                type: "CropImage"
                path: "/files/images/{args.id}/crop"
                method: "POST"
            ) {
            id
            file {
                url
                width
                height
            }
            originalFileName
            mimeType
            uploaded
            variants
        }
    }
`;
